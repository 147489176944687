@import "../../../../assets/colors";

.upgrade-actions {
  display: flex;
  flex-direction: row;

  .button {
    min-width: 200px;

    &:first-child {
      margin-right: 16px;
    }

    &:last-child {
      margin-left: 16px;
    }
  }
}

.upgrade-popup-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: $codGray60;
  opacity: 1;
  z-index: 10;
  transition: z-index, opacity .1s ease-in;

  .upgrade-popup {
    max-height: 90%;
    background-color: $white;
    transition: z-index, opacity 0.2s ease-in;
    transition-delay: 0.1s;
    box-sizing: border-box;
    padding: 50px 32px 32px 32px;
    overflow-y: auto;

    .header {
      height: calc(24px + 2 * 10px);
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-right: 10px;
      position: relative;

      > p {
        color: $codGray;
        font-size: 16px;
        line-height: 20px;
        margin-left: 24px;
        margin-right: 24px;
      }

      > i {
        position: absolute;
        right: -30px;
        top: -40px;
        width: 24px;
        color: $codGray;
        cursor: pointer;
        background-color: $athensGray;
        padding: 5px;
        border-radius: 50%;
        transform-origin: center;

        &:hover {
          color: $alto;
          transform: scale(1.05);
        }
      }
    }

    .message {
      text-align: center;
      font-size: 13px;
      color: $codGray;
    }

    .form {
      padding: 32px 16px;

      .user-info {
        p {
          margin-top: 30px;
          margin-bottom: 16px;
          color: #1E1E1E;
          font-size: 18px;
          font-weight: bold;
        }
      }
    }

    .actions {
      display: flex;
      flex-direction: row;
      justify-content: space-evenly;

      .button {
        min-width: 150px;
      }
    }
  }
}
