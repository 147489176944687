@import '../../../assets/colors';

.drop-down {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20%;
  min-width: 140px;
  border: 1px solid rgba($cornflowerBlue, 0.3);
  border-radius: 3px;
  margin: 5px;

  .box {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 40px;
    cursor: pointer;
    box-sizing: border-box;
    padding: 5px 15px;

    .current {
      color: $armadillo;
      font-size: 13px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .arrow {
      max-width: 0;
      height: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-top: 6px solid $bombay;
      transition: transform 0.2s ease-in;

      &.up {
        transform: rotate(180deg);
      }
    }

    &.disabled {
      cursor: not-allowed;
    }
  }

  .list {
    position: absolute;
    right: 0;
    left: 0;
    max-height: 600px;
    overflow-y: scroll;
    top: 45px;
    flex-direction: column;
    background-color: $white;
    opacity: 0;
    z-index: -1;
    place-content: flex-start;
    transition: opacity 0.2s ease-in;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.08),
      0 1px 2px 0 rgba(0, 0, 0, 0.1);

    .row {
      display: flex;
      align-items: center;
      width: 100%;
      box-sizing: border-box;
      padding: 12px 12px;
      transition: background-color 0.2s ease-in;

      p {
        color: $armadillo;
        font-size: 13px;
      }

      &:hover {
        background-color: $athensGray;
        cursor: pointer;
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &.display {
      opacity: 1;
      z-index: 10;
    }
  }
}
