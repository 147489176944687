@import "../../../../../assets/colors";
@import "../../../../../assets/dimens";

#projectUsers {
  > .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .title {
      font-size: $title;
      font-weight: bold;
    }
  }

  .no-users {
    text-align: center;
  }

  .horizontal-dot-separator {
    margin-top: 20px;
    margin-bottom: 0;
  }

  .users-item {
    display: flex;
    flex-direction: column;

    &:hover{
      background-color: $athensGray;
      cursor: pointer;
    }

    .content{
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;

      .user-badge {
        display: flex;
        justify-content: center;
        align-items: center;
        color: $white;
        border-radius: 2px;
        min-width: 25px;
        min-height: 25px;
        margin-right: 15px;
        font-size: $labelButton;

        &.supervisor {
          background-color: $londonHue
        }

        &.mentor {
          background-color: $roseFog
        }

        &.facilitator {
          background-color: $cerulean
        }

        &.admin {
          background-color: $cerulean
        }
      }

      .identity {
        font-size: 13px;
        color: $codGray;
        width: 30%;
        font-weight: bold;
        overflow: hidden;

        p {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          margin-right: 15px;
        }
      }

      .email {
        font-size: 12px;
        color: $codGray;
        width: 50%;
        overflow: hidden;

        p {
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
        }
      }

      .phone {
        font-size: 12px;
        color: $codGray;
        width: 20%;
        text-align: right;
        padding-right: 20px;
      }

      i {
        margin-right: 4px;
        color: $bombay;

        &:hover {
          color: darken($bombay, 10);
        }
      }
    }

    .user-separator {
      width: 100%;
      border: 1px dotted $athensGray;
      border-right: none;
      border-left: none;
    }
  }
}
