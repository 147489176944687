@import '../../../../assets/colors';
@import '../../../../assets/dimens';

#projectItem {
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;

  .project-item-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    &.allowed:hover{
      background-color: $athensGray;
      cursor: pointer;
    }

    .separator {
      border: 1px dotted $athensGray;
      border-right: none;
      border-left: none;
      margin-top: 20px;
    }

    .name {
      color: $codGray;
      font-size: 18px;
      font-weight: bold;
      flex:10;
    }

    .status {
      display: flex;
      flex-direction: row;
      align-items: center;

      p {
        color: $doveGray;
        &:first-of-type {
          margin-right: 10px;
        }
      }

      .state {
        height: 15px;
        width: 15px;
        background-clip: content-box;
        border: 1px solid $bombay;
        border-radius: 15px;
        padding: 2px;
        margin-right: 10px;

        &.comming {
          background-color: $treePoppy;
        }

        &.validated {
          background-color: $atlantis;
        }

        &.finished {
          background-color: $red;
        }
      }
    }

    .type {
      display: flex;
      color: $lynch;
      font-size: 13px;
      flex: 3;
      flex-direction: column;
      justify-content: flex-end;

      .challenge {
        height: 55px;
      }

      img{
        width: 100%;
        height: 100%;
      }
    }

    i {
      margin: 0 4px;
      color: $bombay;

      &:hover {
        color: darken($bombay, 10);
      }
    }
  }
}
