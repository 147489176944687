@import "../../../assets/colors";

#footer {
  height: 100px;
  min-height: 100px;
  background-color: $ceruleanLight;

  > .content {
    display: flex;
    flex-direction: row;
    height: 100%;
    align-items: center;
    box-sizing: border-box;
    padding: 0 50px;
    color: $white;

    #link {
      display: flex;
      flex-direction: row;
      align-items: center;
      flex: 1;

      p {
        margin: 0 20px;

        &:hover {
          color: $white70;
          cursor: pointer;
        }
        &:active{
          color: $white30;
        }
      }
    }

    #go-web-epa {

      a {
        &:last-child {
          font-weight: bold;
        }
      }

      &:hover {
        color: $white70;
        cursor: pointer;
      }
      &:active{
        color: $white30;
      }
    }
  }
}
