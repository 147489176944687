@import '../../../assets/colors';
@import '../../../assets/dimens';

.record-info {
  .title {
    p {
      font-size: 24px;
    }
  }

  .separator {
    border: 1px dotted rgba($alto, .7);
    border-right: none;
    border-left: none;
    margin-top: 10px;
  }

  .container {
    display: flex;
    flex-direction: row;

    > div {
      flex: 1;
      margin-top: 16px;

      &:first-of-type {
        margin-right: 16px;
      }

      &:last-of-type {
        margin-left: 16px;
      }
    }

    &.email {
      width: 50%;

      > div {
        &:last-of-type {
          margin-left: 0;
        }
      }
    }
  }
}
