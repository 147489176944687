@import '../../../assets/colors';
@import '../../../assets/dimens';


#projectsList {
  .title {
    div {
      font-size: 24px;
    }
  }

  .editable {
    border: none;
    background: #D1D1D1;
    width: fit-content;
    padding: 0 15px;
    border-radius: 2px;
    height: 40px;
    outline: none;
  }

  .non-editable {
    border: none;
    width: fit-content;
    border-radius: 2px;
    height: 40px;
    pointer-events: none;
  }
  
  .separator {
    border: 1px dotted rgba($alto, .7);
    border-right: none;
    border-left: none;
    margin-top: 10px;
  }

  .list {
    padding-top: 30px;

    .no-projects {
      text-align: center;
    }

    #projectItem {

      .project-item-title {
        padding-bottom: 10px;
        padding-top: 10px;
      }

      > .separator {
        margin-top: 0;
      }
    }
  }

  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color: $codGray;
    font-size: 16px;
    font-weight: bold;

    .name {
      font-size: 24px;
    }

  
    .edit-button {
      color: #fff;
      height: 36px;
      width: 105px;
      padding-left: 15px;

      @include width800px {
        height: 24px;
        width: 100px;
      }

      span { font-size:  12px}
      .material-icons { font-size: 19px  }
    }
  }
}
