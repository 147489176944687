@import "../../../assets/colors";
@import "../../../assets/dimens";

#userSummary {
  .separator {
    border: 1px dotted $alto;
    border-right: none;
    border-left: none;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .name-wrapper {
    display: flex;
    flex-direction: row;

    .name {
      flex: 1;

      

      &.consultation {
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: hidden;

        p {
          height: 32px;
          font-size: 24px;
          font-weight: bold;
          color: $codGray;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @include width800px {
            font-size: 20px;
          }
        }
      }

      &.edition {
        display: flex;
        flex-direction: row;

        > div {
          margin-left: 12px;
          margin-right: 12px;

          &:first-of-type {
            margin-left: 0;
          }

          &:last-of-type {
            margin-right: 0;
          }

          .label {
            display: none;
          }

          input, p.current {
            font-size: 24px;
            font-weight: bold;
            color: $codGray;
          }
        }
      }
    }

    .edit-button {
      width: 125px;
      height: 36px;
      padding-left: 15px;
      display: flex;
      justify-content: space-between;
      color: #FFFFFF;
      margin-left: 12px;

      @include width800px {
        height: 24px;
        width: 100px;
      }

      span {
        font-size: 12px;
      }
    }
  }

  .user-summary {

    .form-container {
      padding: 8px 0;

      .contact {
        display: flex;

        > div {
          flex: 1;
        }

        .email {
          margin-right: 30px
        }
      }

      .region {
        width: calc((100% - 30px) / 2);
        margin-top: 16px;
        margin-bottom: 16px;
      }
    }

    .rights {
      padding-top: 8px;
    }
  }
}


