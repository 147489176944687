@import "../../../../assets/colors";

#miniList {
  .list-wrapper {
    max-height: 60vh;
    
    .mini {
      position: relative;
      display: flex;
      flex-direction: row;
      margin: 4px 0;
      border-radius: 2px;
      background-color: white;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1),
        0 1px 3px 0 rgba(145, 145, 145, 0.3);

      .line {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .column {
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .separator {
        width: 100%;
        height: 1px;
        background: rgba(0, 0, 0, 0.2);
      }

      .vertical-separator {
        width: 1px;
        height: 100%;
        background: rgba(0, 0, 0, 0.2);
      }

      .check {
        width: 24px;
        margin: 10px;
        align-self: flex-start;
      }

      .content {
        flex: 1;
        cursor: pointer;

        .info {
          .start {
            width: 55%;
            padding: 12px 16px;

            .name {
              margin-bottom: 12px;
              color: black;
              font-size: 13px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 16px;
            }

            .partners {
              min-height: 12px;
              margin-top: 8px;

              p {
                color: $doveGray;
                font-size: 12px;
                letter-spacing: 0;
                line-height: 12px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                span {
                  margin-right: 24px;
                }
              }
            }
          }

          .end {
            width: 45%;
            padding: 14px 16px 10px;

            > div:first-of-type {
              flex: 1;

              .date {
                margin-bottom: 16px;

                .label {
                  margin-right: 12px;
                  color: $doveGray;
                  font-size: 12px;
                  letter-spacing: 0;
                  line-height: 12px;
                  text-align: right;
                }
              }

              .date .value,
              .place {
                color: black;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 16px;
              }
            }

            .image-wrapper {
              width: 110px;

              img {
                width: 100%;
              }
            }
          }
        }

        .counts {
          padding: 8px 0 8px 16px;
          justify-content: space-between;

          .item {
            .pins {
              min-width: 30px;
              height: 18px;
              display: flex;
              justify-content: center;
              align-items: center;
              margin-right: 8px;
              border-radius: 2px;
              color: white;
              font-size: 12px;

              &.supervisors {
                background: $mountainMeadow;
              }
              &.mentors {
                background: $sun;
              }
              &.facilitators {
                background: $bondiBlue;
              }
              &.schools {
                background: $flamingo;
              }
            }

            .category {
              margin-right: 30px;
              color: $doveGray;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 12px;
            }

            .value {
              color: $shark;
              font-size: 13px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 16px;
            }

            i {
              margin: 0 16px;
              font-size: 14px;
              color: $sorbus;
            }
          }
        }
      }

      .status {
        align-items: flex-start;
        .column {
          height: 100%;
          justify-content: space-between;
          padding: 12px 16px;
          box-sizing: border-box;

          .action-popup .header > i {
            right: 2px;
          }

          .state {
            height: 15px;
            width: 15px;
            background-clip: content-box;
            border: 1px solid $bombay;
            border-radius: 15px;
            padding: 2px;

            &.draft {
              background-color: $flamingo;
            }

            &.running {
              background-color: $atlantis;
            }

            &.finished {
              background-color: $cerulean;
            }
          }
        }
      }
    }
  }
}
