@import '../../../assets/colors';
@import '../../../assets/dimens';

.lateral-menu {
  display: flex;
  position: fixed;
  top: 0;
  right: 0;
  width: 75%;
  height: 100%;
  transition: z-index;

  &.show {
    transition-delay: 0s;
    z-index: 10;
  }

  &.hide {
    transition-delay: 0.3s;
    z-index: -1;
  }

  > .content {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: $white;
    transition: transform 0.3s ease-in;
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(145, 145, 145, 0.3);

    &.show {
      transform: translateX(0%);
    }

    &.hide {
      transform: translateX(100%);
    }

    .header {
      position: relative;
      display: flex;
      align-items: center;
      height: 12%;
      width: 100%;
      box-sizing: border-box;
      padding: 30px;
      background-color: $white;
      border-bottom-style: solid;
      border-bottom-color: $athensGray;
      border-bottom-width: 1px;

      .title {
        font-size: $title;
        color: $cerulean;
      }

      > i {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $bombay;

        &:hover {
          top: 7px;
          right: 7px;
          font-size: 30px;
          color: $bombay70;
          cursor: pointer;
        }
      }
    }
    .add-school {
      display: flex;
      align-items: center;
      .action-button {
        margin: 1rem !important;
      }
    }
    .filter-bar {
      width: 100%;
      height: 39%;
      background-color: $white;

      .filter {
        height: 56%;
      }
    }

    .list-lateral {
      position: relative;
      overflow-y: scroll;
      height: 70%;
      width: 100%;
      background-color: $athensGray;
      .list {
        max-height: 99.9%;
      }
      .paginate {
        box-shadow: 3px 3px 1px 0 rgba(0, 0, 0, 0.1),
          3px 0 1px 0 rgba(145, 145, 145, 0.3);
      }
    }

    .add {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      box-sizing: border-box;
      padding: 30px;
      height: 10%;
      width: 100%;

      .add-button {
        width: 200px;
      }
    }
  }
}
#school-creation-screen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 0px !important;
  margin-bottom: 32px !important;
  padding: 0 5% !important;
  overflow: auto;
  .side-bar {
    width: 25%;
  }

  .school-creation {
    display: flex;
    width: 75%;
    flex-direction: column;

    .button {
      display: flex;
      align-self: flex-end;
      justify-content: center;
      color: $white;
      height: 40px;
      width: 172px;
      margin-bottom: 2rem !important;

      span {
        font-size: $labelButton;
      }
    }
  }
}
@media screen and (max-width: 940px) {
  #school-details-screen {
    flex-direction: column;

    .side-bar {
      display: none;
    }

    .school-creation {
      width: 100%;
    }
  }
}
