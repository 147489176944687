@import "../../../assets/colors";

.radio-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;

  img {
    height: 36px;
  }

  .radio-button {
    display: block;
    position: relative;
    user-select: none;
    padding-left: 35px;
    color: $armadillo;
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
    margin-left: 40px;
    margin-right: 20px;
    // height: 36px;
    cursor: pointer;

    span {
      position: absolute;
      top: calc(50% - 8px);
      left: 0;
      height: 16px;
      width: 16px;
      border: 2px solid $codGray;
      border-radius: 50%;

      &:after {
        content: "";
        position: absolute;
        display: none;
        top: 3px;
        left: 3px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $cerulean;
      }
    }

    &.checked {
      span {
        border: 2px solid $cerulean;

        &:after {
          display: block;
        }
      }
    }
  }

  &.disabled {
    cursor: default;
    
    .radio-button {
      cursor: default;
    }
  }
}

