@import "../../../../assets/colors";

#authWrapper {
  height: 100vh;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $bondiBlue;

  .epa-background {
    width: 50%;
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1;
  }

  .header {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    position: absolute;
    left: 38px;
    top: 38px;

    .epa-logo {
      width: auto;
      height: 40px;
    }

    .label {
      margin: 0 0 0 24px;
      color: $white;
      font-size: 34px;
      line-height: 32px;
    }
  }

  #formContainer {
    width: 100%;
    z-index: 2;
    position: relative;
    max-width: 570px;
    min-width: 280px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 2px;
    background-color: $white;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.12), 0 6px 6px 0 rgba(0, 0, 0, 0.24), 0 0 12px 0 rgba(0, 0, 0, 0.12), 0 12px 12px 0 rgba(0, 0, 0, 0.24);

    .title {
      margin: 60px 80px 36px 80px;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
      color: $codGray;
      text-align: center;
    }

    > .separator {
      width: 100%;
      margin: 0;
      border-top: dotted 1px $alto;
      border-bottom: none;
      border-left: none;
      border-right: none;
    }

    #form {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 20px 0 48px 0;
      box-sizing: border-box;
    }
  }
}


@media screen and (max-width: 640px) {
  #authWrapper {
    overflow-y: scroll;
    justify-content: normal;

    .epa-background {
      display: none;
    }

    .header {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      top: 0;
      left: 0;
      margin: 24px;
    }
    
    #formContainer {
      .title{
        margin: 60px 40px 36px 40px;
      }
    }
  }
}
