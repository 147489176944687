@import "../../../../assets/colors";
@import "../../../../assets/dimens";

#facilitators{
  display: flex;
  flex-direction: column;

  .content {
    .footer {
      width: calc(100% - 6px);
    }

    .options {
      margin-right: 5px;
    }
  }

  .no-list{
    align-self: center;
  }
}
