@import "../../../../assets/colors";
@import "../../../../assets/dimens";

#project-screen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: $marginTopContent;
  padding: 0 5%;
  overflow-y: scroll;

  .side-bar {
    width: 25%;

    .wrapper {
      display: flex;
      flex-direction: column;
      position: fixed;

      #sideBar {
        position: relative;
      }

      .actions {
        margin-top: 16px;

        .button {
          min-width: 175px;
          margin-top: 16px;
        }
      }
    }
  }

  .project {
    width: 75%
  }
}

@media screen and (max-width: 940px) {
  #project-screen {
    flex-direction: column;

    .side-bar {
      display: none
    }

    .project {
      width: 100%
    }
  }
}
