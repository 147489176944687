@import "../../../../assets/colors";
@import "../../../../assets/dimens";

#freemiums {
  display: flex;
  flex-direction: column;

  .content {
    justify-content: center;

    .options {
      margin-right: 5px;
    }

    .action-popup {
      top: 6px;
    }
  }

  .no-list {
    align-self: center;
  }
}
