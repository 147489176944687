@import '../../../../assets/colors';

#userBadge {
  display: flex;
  justify-content: center;
  align-items: center;
  color:#fff;
  border-radius: 2px;
  width: 24px;
  height: 24px;
}

.supervisor { background:  $londonHue}
.mentor { background: $roseFog }
.facilitator { background: $cerulean}
.admin { background: $cerulean }
.other { background: $bombay}
