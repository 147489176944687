@import "../../../assets/colors";

.paginate-wrapper {
  > ul {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;

    li {
      list-style: none;
      margin: 0 5px;

      &:first-child {
        position: absolute;
        left: 0;
      }

      &:last-child {
        position: absolute;
        right: 0;
        align-self: flex-end;
      }

      a {
        color: $nevada;
        display: flex;
        font-size: 24px;
        padding: 8px;
        outline: none;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 4px;
        box-sizing: border-box;
        font-weight: bold;
        text-transform: uppercase;
      }

      a.selected {
        color: $bondiBlue;
      }

      &.disabled {
        color: $bombay;

        a {
          cursor: not-allowed;

          &:hover {
            background-color: $athensGray;
          }
        }
      }
    }

  }
}
