@import "../../../assets/colors";

#projectNavigation {
  width: 100%;
  display: flex;
  flex-direction: row;
  padding: 12px 48px;
  background: darken($bondiBlue, 5%);
  box-sizing: border-box;

  p {
    margin-right: 48px;
    color: white;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 22px;
    cursor: pointer;

    &.selected {
        font-weight: bold;
    }
  }
}

@media screen and (max-width: 800px) {
  #screenWrapper {
    padding: 12px 24px;
  }
}
