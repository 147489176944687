@import "../../../../assets/colors";

#adminRights {
  display: flex;
  flex-direction: row;
  margin-top: 30px;
  margin-bottom: 16px;

  .subtitle {
    margin-right: 32px;
    color: $codGray;
    font-size: 18px;
    font-weight: bold;
  }
}
