@import "../../../../assets/colors";
@import "../../../../assets/dimens";

.project-list-item {
  position: relative;
  // display: -webkit-box;
  // display: -webkit-box;  /* OLD - iOS 6-, Safari 3.1-6, BB7 */
  // display: -ms-flexbox;  /* TWEENER - IE 10 */
  display: -webkit-flex; /* NEW - Safari 6.1+. iOS 7.1+, BB10 */
  // display: flex;         /* NEW, Spec - Firefox, Chrome, Opera */
  flex-direction: row;
  // justify-content: space-between;
  width: 100%;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 2px 3px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.2);
  margin: 10px 0;
  background-color: $white;
  min-height: 100px;

  > .check {
    display: flex;
    justify-content: center;
    width: 50px;
    box-sizing: border-box;
    padding: 15px;
    border-right: 1px solid $bombay;

    .checkbox {
      width: 25px;
      height: 25px;
      display: flex;

      .checkmark {
        width: 21px;
        height: 21px;

        &::after {
          left: 6px;
          top: 2px;
        }
      }
    }
  }

  > .content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 50px);
    flex:1;
    overflow: hidden;

    .header {
      display: flex;
      flex-direction: row;
      height: 60%;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 0 0 0 20px;
      border-right: 1px solid $bombay;
      background-color: white;

      .label {
        width: 30%;
        box-sizing: border-box;
        padding: 10px 10px 10px 0;
      }

      .info {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        height: 100%;
        width: 70%;
        max-width: 70%;
        overflow: hidden;

        .place {
          display: flex;
          align-items: center;
          max-width: 85%;

          p{
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            margin-left: 20px;
          }
        }

        .time {
          display: flex;
          width: 15%;
          min-width: 15%;
          overflow: hidden;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
          border-left: 1px solid $bombay;
          font-weight: bold;
          padding: 0;

          .challenge {
            padding: 0 20px;
          }

          img{
            width: 100%;
          }
        }
      }
    }

    .footer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid $bombay;
      border-right: 1px solid $bombay;
      box-sizing: border-box;
      padding: 10px 65px 10px 20px;
      background-color: $white;

      .members {
        display: flex;
        flex-direction: row;

        .item {
          display: flex;
          flex-direction: row;
          margin-right: 70px;

          .pins {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 18px;
            border-radius: 2px;
            color: $white;
            margin-right: 10px;
            font-size: 12px;
            // box-shadow: 0 1px 2px 0 rgba(0,0,0, 1), 0 1px 3px 0 rgba(145,145,145, 1);


            &.projects {
              background-color: $atlantis;
            }

            &.supervisors {
              background-color: $londonHue;
            }

            &.mentors {
              background-color: $roseFog;
            }

            &.facilitators {
              background-color: $cerulean;
            }

            &.schools {
              background-color: $flamingo;
            }
          }

          .member-poste {
            line-height: 20px;
          }
        }
      }

      .other-details {
        display: flex;
        flex-direction: row;
        align-items: center;

        p {
          &:first-of-type {
            margin-right: 10px;
          }
        }

        .code {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 15px;

          i {
            color: $cerulean;
            margin-right: 10px;
          }
        }

        .password {
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-right: 15px;

          i {
            color: $cerulean;
            margin-right: 10px;
          }
        }
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .pie {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 75px;
    width: 100px;
    box-sizing: border-box;
    padding: 20px;

    p {
      position: absolute;
      top: 31px;
      font-size: 13px;
    }

    i {
      position: absolute;
      top: 10px;
      right: 0;
      color: $codGray;

      &:hover {
        cursor: pointer;
      }
    }

    &:hover {
      cursor: pointer;
    }
  }

  .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    p {
      color: $doveGray;
      &:first-of-type {
        margin-right: 10px;
      }
    }

    .state {
      height: 15px;
      width: 15px;
      background-clip: content-box;
      border: 1px solid $bombay;
      border-radius: 15px;
      padding: 2px;
      margin-right: 10px;

      &.comming {
        background-color: $treePoppy;
      }

      &.validated {
        background-color: $atlantis;
      }

      &.finished {
        background-color: $red;
      }
    }
  }

  .action-popup{
    .header{
      margin-right: 0;
    }
  }

  .bold {
    font-weight: bold;
  }

  p {
    color: $codGray;
    font-size: 13px;
  }
}
