@import "../../../../assets/colors";
@import "../../../../assets/dimens";

#project {
  display: flex;
  flex-direction: column;
  max-width: 100vw;
  overflow-x: hidden;

  .checkboxes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 8px 20px;

    .checkbox .label {
      height: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $black;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }

  .no-list{
    align-self: center;
  }

  .wrapper-list {
    max-height: 55vh;
  }
}
