$marginTopContent: 50px;
$title: 24px;
$labelButton: 12px;
$iconButton: 19px;

@mixin width800px {
  @media screen and (max-width: 800px) {
    @content
  }
}

@mixin width1180px {
  @media screen and (max-width: 1180px) {
    @content
  }
}
