@import "../../../../assets/colors";

.notif-creation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: $white;

  > .wrapper {
    display: flex;
    flex-direction: row;
    padding: 48px;

    .side-navigation {
      width: 140px;
      position: fixed;
      top: calc(72px + 32px);
      display: flex;
      flex-direction: column;
      align-items: center;

      .button {
        padding: 8px 16px;
      }
    }

    .form {
      width: 770px;
      display: flex;
      flex-direction: column;
      margin-left: 240px;

      > .title {
        margin-bottom: 24px;
        color: $codGray;
        font-size: 32px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 32px;
      }

      .horizontal-dot-separator {
        margin: 16px 0;
      }

      .section {
        > .title {
          color: $codGray;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 24px;
        }

        &:first-of-type {
          .horizontal-dot-separator:first-of-type {
            margin: 16px 0 8px 0;
          }

          .horizontal-dot-separator:last-of-type {
            margin: 8px 0 16px 0;
          }
        }

        .recipients-wrapper {
          display: flex;
          flex-direction: row;

          .recipients {
            flex: 1;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            .recipient {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 16px;
              margin: 8px;
              border: 1px solid $alto;
              border-radius: 2px;

              p {
                color: $armadillo;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 24px;
                white-space: nowrap;
              }

              i {
                width: 16px;
                height: 16px;
                padding: 2px;
                margin-left: 12px;
                font-size: 16px;
                background: $rollingStone;
                border-radius: 50%;
                color: $white;
                cursor: pointer;
              }
            }
          }

          .add-button {
            width: 192px;
            margin-top: 8px;
          }
        }

        .include {
          display: flex;
          flex-direction: row;
          justify-content: flex-end;
          align-items: center;
          margin-bottom: 24px;

          > p {
            margin-right: 24px;
            color: $black;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 18px;
          }
        }

        .courses {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          row-gap: 12px;
          margin-bottom: 40px;

          .course {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin: 0 64px;

            img {
              width: auto;
              height: 40px;
              margin-left: 24px;
            }
          }
        }

        &.content {
          margin-bottom: 24px;

          .text-area-wrapper {
            margin-top: 8px;
          }
        }

        .button-form {
          display: flex;
          flex-direction: row;
          align-items: flex-end;

          .wrapper {
            flex: 1;
            display: flex;
            flex-direction: row;

            .title {
              width: calc((100% - 12px) * 0.35);
              margin-right: 6px;
            }

            .link {
              width: calc((100% - 12px) * 0.65);
              margin-left: 6px;
            }
          }

          .test {
            height: 28px;
            width: 64px;
            margin-left: 16px;

            span {
              font-size: 12px;
            }
          }
        }
      }

      .error-message {
        text-align: right;
        margin-bottom: 16px;
        color: $red;
      }

      .actions {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        .cancel {
          width: 166px;
        }

        .send {
          width: 222px;
        }

        div:first-of-type {
          margin-left: 12px;
        }

        div:last-of-type {
          margin-left: 12px;
        }
      }
    }
  }

  .lateral-panel {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    width: 75%;
    height: 100%;
    transition: z-index;

    &.show {
      transition-delay: 0s;
      z-index: 10;
    }

    &.hide {
      transition-delay: 0.3s;
      z-index: -1;
    }

    > .container {
      display: flex;
      flex-direction: column;
      width: 100%;
      background-color: $white;
      transition: transform 0.3s ease-in;
      box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1),
        0 0 0 1px rgba(145, 145, 145, 0.3);

      &.show {
        transform: translateX(0%);
      }

      &.hide {
        transform: translateX(100%);
      }

      .header {
        position: relative;
        display: flex;
        align-items: center;
        height: 12%;
        width: 100%;
        box-sizing: border-box;
        padding: 30px;
        background-color: $white;
        border-bottom-style: solid;
        border-bottom-color: $athensGray;
        border-bottom-width: 1px;
        z-index: 15;

        .title {
          width: 100%;
          color: $ceruleanLight;
          font-size: 34px;
          letter-spacing: 0;
          line-height: 32px;
        }

        > i {
          position: absolute;
          top: 10px;
          right: 10px;
          color: $bombay;

          &:hover {
            top: 7px;
            right: 7px;
            font-size: 30px;
            color: $bombay70;
            cursor: pointer;
          }
        }
      }

      .filter-bar {
        width: 100%;
        height: 10%;
        display: flex;
        flex-direction: row;
        align-items: center;
        background-color: $white;
        box-shadow: 0 4px 16px 0 rgba(104, 129, 151, 0.15),
          0 8px 26px 0 rgba(0, 0, 0, 0.15);
        z-index: 10;

        .filter {
          flex: 1;
          margin-right: 24px;
          margin-bottom: 0;
          padding: 0;
          box-shadow: none;
        }

        #searchBar {
          width: 300px;
        }
      }

      .items {
        width: 100%;
        flex: 1;
        position: relative;
        padding: 32px;
        overflow: hidden;
        background-color: $athensGray;

        .select-all {
          margin-left: 12px;
          margin-bottom: 32px;
        }

        .list-wrapper {
          height: calc(100% - 24px);

          .container {
            height: calc(100% - 56px - 8px);
            padding: 0;
            overflow-y: auto;

            .item {
              display: flex;
              flex-direction: row;
              align-items: center;
              padding: 12px;
              border-bottom: 1px solid $alto;

              p {
                color: $armadillo;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 24px;
              }

              &:first-of-type {
                border-top: 1px solid $alto;
              }
            }
          }
        }
      }

      .add {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        box-sizing: border-box;
        padding: 30px;
        height: 10%;
        width: 100%;

        .add-button {
          width: 200px;
        }
      }
    }
  }
}
