@import "../../../assets/colors";

#params {
  min-height: calc(100vh - 100px - 72px);
  display: flex;
  flex-direction: row;

  ul.menu {
    width: 250px;
    max-height: calc(100vh - 72px - 100px);
    display: flex;
    flex-direction: column;
    position: fixed;
    overflow: auto;
    background: $white;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.1), 0 0 0 2px rgba(145, 145, 145, 0.3);

    li {
      padding: 12px 24px;
      cursor: pointer;
      border-bottom: solid 1px $doveGray;

      &.selected {
        background: darken($wildSand, 15);
      }
    }
  }

  .container {
    flex: 1;
    padding: 36px;
    margin-left: 250px;

    .title {
      margin-bottom: 24px;
      color: $codGray;
      font-size: 24px;
      font-weight: bold;
      line-height: 32px;
    }

    hr {
      width: 100%;
      margin: 0;
      border-top: dotted 1px $alto;
      border-bottom: none;
      border-left: none;
      border-right: none;
    }

    ul {
      margin-top: 24px;
      list-style: none;

      .cell {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 6px 0;
        background: $wildSand;
        box-sizing: border-box;
        margin-bottom: 8px;

        > i {
          margin: 12px;
        }

        > div {
          display: flex;
          flex-direction: row;
          align-items: center;
          flex: 1;
          margin-left: 12px;

          input {
            height: 48px;
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-left: 16px;
            padding-right: 16px;
            font-size: 16px;
            box-sizing: border-box;
            border: 1px solid $geyser;
            border-radius: 2px;
            background-color: white;

            &.invalid {
              border: 1px solid $scarlet;
            }

            &:focus {
              outline: none;
            }
          }

          .material-icons {
            color: $scarlet;
            cursor: pointer;
            padding: 16px;

            &:hover {
              color: lighten($scarlet, 10);
            }
          }
        }
      }

      .cell.operation-cell {
        > div {
          align-items: flex-end;
          margin-bottom: 8px;

          .operation {
            margin: 0 24px;

            .form-drop-down {
              width: 150px;

              .box {
                height: 48px;
                border: 1px solid $geyser;
                border-radius: 2px;
                background-color: white;
              }
            }
          }
        }
      }
    }

    .add-wrapper {
      display: flex;
      flex-direction: row-reverse;
      margin: 24px;

      .add {
        padding: 6px 16px 6px 16px;

        span {
          margin-right: 12px;
        }

        .material-icons {
          color: $white;
        }
      }
    }

    .actions-wrapper {
      display: flex;
      flex-direction: row;

      .button {
        flex: 1;
        margin: 24px;
      }
    }
  }
}
