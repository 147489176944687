@import "../../../assets/colors";
@import "../../../assets/dimens";

#schoolInfo {
  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    > .input-wrapper {
      flex: 1;
      margin-right: 10px;

      .title {
        height: 52px;
        font-size: $title;
        font-weight: bold;
        color: $codGray;
      }
    }

    .edit-button {
      display: flex;
      justify-content: space-between;
      color: $white;
      height: 36px;
      width: 125px;
      margin-left: 12px;

      span {
        font-size: $labelButton;
      }

      i {
        font-size: $iconButton;
      }
    }
  }

  .separator {
    width: 100%;
    border: 1px dotted $alto;
    border-right: none;
    border-left: none;
    margin: 10px 0;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    padding: 8px 0;

    .line {
      flex: 1;
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;

      .subcontainer {
        display: flex;
        flex-direction: row;
      }

      

      .full {
        flex: 3;

        &:first-of-type {
          margin-right: 30px;
        }
      }

      .medium {
        flex: 2;
      }

      .small {
        flex: 1;
        padding-right: 30px;
      }
    }
  }

  .subtitle {
    margin-top: 30px;
    margin-bottom: 16px;
    color: $codGray;
    font-size: 18px;
    font-weight: bold;
  }

  .disclaimer {
    margin-bottom: 24px;
    color: $black;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 18px;
  }
}

@media screen and (max-width: 800px) {
  #school-info {
    .separator {
      display: none;
    }

    .header {
      > .input-wrapper {
        .title {
          font-size: 20px;
        }
      }

      .edit-button {
        height: 24px;
        width: 100px;
        margin: 0;
      }
    }
    .form-container {
      .line {
        flex-direction: column;
        margin-bottom: 0;
        
        div:first-child {
          margin-bottom: 10px;
        }

        .full {  
          &:first-of-type {
            margin-right: 0px;
          }
        }
      }
    }
  }
}
