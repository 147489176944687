@import "../../../../assets/colors";

#mentorProfile {

  .subtitle {
    margin-top: 30px;
    margin-bottom: 16px;
    color: $codGray;
    font-size: 18px;
    font-weight: bold;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    padding: 8px 0;

    .line {
      flex: 1;
      display: flex;
      flex-direction: row;
      margin-bottom: 16px;

      .subcontainer {
        display: flex;
        flex-direction: row;
      }

      .full {
        flex: 3;

        &:first-of-type {
          margin-right: 30px;
        }
      }

      .medium {
        flex: 2;
      }

      .small {
        flex: 1;
        padding-right: 30px;
      }
    }
  }
}
