@import "../../../assets/colors";

#reinscription {
  min-height: calc(100vh - 72px - 100px);
  background: $white;

  > .container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    #sideNavigation {
      width: 180px;
    }

    > .wrapper {
      width: calc(100% - 6% - 180px);
      display: flex;
      flex-direction: column;
      align-items: center;

      .step-container {
        width: 570px;

        > .title {
          padding-top: 32px;
          padding-bottom: 20px;
          color: $codGray;
          font-size: 32px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 32px;
        }

        .header {
          display: flex;
          flex-direction: column;

          .title {
            display: flex;
            flex-direction: row;
            align-items: center;

            .step {
              color: $codGray;
              font-size: 18px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 24px;
            }

            .label {
              margin-left: 16px;
              color: $codGray;
              font-size: 24px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 32px;
            }
          }

          .subtitle {
            margin-top: 32px;
            color: $black;
            font-size: 13px;
            letter-spacing: 0;
            line-height: 18px;
          }
        }

        .existing-projects {

          .horizontal-dot-separator {
            margin: 16px 0 0 0;
          }

          li {

            .header {
              display: flex;
              flex-direction: row;
              align-items: center;

              .checkbox {
                width: 24px;
                height: 24px;
              }

              .name {
                margin-left: 16px;
                color: $codGray;
                font-size: 18px;
                font-weight: bold;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              }

              .duration {
                flex: 1;
                margin: 0 32px;
                font-size: 12px;
                color: $doveGray;
                white-space: nowrap;
              }

              .type {
                width: 130px;
                display: flex;
                color: $lynch;
                font-size: 13px;
                flex-direction: column;
                justify-content: flex-end;

                .challenge {
                  height: 55px;

                  img {
                    height: 100%;
                  }
                }

                img {
                  width: 100%;
                }
              }
            }

            .users {

              .user-item {
                padding: 10px 0;

                &:hover {
                  background: none;
                  cursor: default;
                }
              }

              .user-separator {
                border: none;
                border-top: 1px dotted $alto;
              }

              > div:last-of-type {
                .user-separator {
                  border: none;
                  border-top: 1px solid $alto;
                  margin-bottom: 20px;
                }
              }
            }
          }
        }

        .kept-projects {

          .wrapper {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: flex-start;

            > p {
              height: 24px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              color: $lynch;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 16px;
              white-space: nowrap;
            }

            ul {
              flex: 1;
              margin-left: 32px;

              li {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 8px;

                .name {
                  color: $armadillo;
                  font-size: 13px;
                  font-weight: 500;
                  letter-spacing: 0;
                  line-height: 24px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                .duration {
                  flex: 1;
                  margin: 0 16px;
                  font-size: 11px;
                  color: $doveGray;
                  white-space: nowrap;
                }

                .type {
                  width: 75px;
                  display: flex;
                  flex-direction: column;
                  justify-content: flex-end;

                  .challenge {
                    height: 25px;

                    img {
                      height: 100%;
                    }
                  }

                  img {
                    width: 100%;
                  }
                }

                &:last-of-type {
                  margin-bottom: 0;
                }
              }
            }
          }
        }

        .new-projects {

          ul {

            li {

              .info {
                display: flex;
                flex-direction: row;

                .title {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  color: $codGray;
                  font-size: 18px;
                  font-weight: bold;
                  letter-spacing: 0;
                  line-height: 24px;
                }

                .type {
                  display: flex;
                  flex-direction: row;
                  margin-left: 24px;

                  > div {
                    margin-right: 24px;

                    span {
                      top: 10px;
                    }

                    label {
                      height: 40px;
                      margin-right: 0;
                      margin-left: 0;

                      img {
                        height: 40px;
                      }
                    }

                    &:last-of-type {
                      margin-right: 0;
                    }
                  }
                }

                .delete-wrapper {
                  display: flex;
                  flex-direction: column;
                  justify-content: center;

                  i {
                    color: $codGray;
                  }

                  &:hover {
                    cursor: pointer;

                    i {
                      color: lighten($codGray, 30);
                    }
                  }
                }
              }

              .duration-wrapper {
                display: flex;
                flex-direction: row;
                align-items: flex-end;
                justify-content: space-between;

                .duration {
                  width: 250px;
                }

                .add-button {
                  width: 180px;
                  height: 40px;
                }
              }

              .supervisor-selector {
                margin-top: 24px;
              }

              .users {
                margin-top: 32px;

                > .label {
                  margin-bottom: 12px;
                  color: $codGray;
                  font-size: 18px;
                  font-weight: bold;
                  letter-spacing: 0;
                  line-height: 24px;
                }

                > .horizontal-dot-separator {
                  margin-bottom: 0;
                }

                .existing {

                  li {
                    display: flex;
                    flex-direction: column;

                    .wrapper {
                      width: 100%;
                      display: flex;
                      flex-direction: row;
                      align-items: center;

                      .user-item {
                        width: calc(100% - 24px - 24px);
                        padding: 10px 0;

                        .content {
                          width: 100%;
                        }

                        &:hover {
                          background: transparent;
                          cursor: default;
                        }
                      }

                      i {
                        margin-left: 24px;
                        color: $codGray;
                        transition: color ease-in 0.2s;

                        &:hover {
                          cursor: pointer;
                          color: lighten($codGray, 30);
                        }
                      }
                    }

                    > .horizontal-dot-separator {
                      margin-bottom: 0;
                      margin-top: 0;
                    }
                  }
                }

                .new {
                  .user-form {

                    .wrapper {
                      display: flex;
                      flex-direction: row;
                      margin: 16px 0;

                      .input-wrapper {
                        margin: 0 16px;

                        &:first-of-type {
                          margin-left: 0;
                        }

                        &:last-of-type {
                          margin-right: 0;
                        }

                        &.email, &.phone {
                          flex: 1;
                        }

                        &.firstName {
                          width: calc(50% - 16px);
                        }

                        &.lastName {
                          flex: 27;
                        }
                      }

                      .form-drop-down-wrapper {
                        margin: 0 16px;

                        &:first-of-type {
                          margin-left: 0;
                        }

                        &:last-of-type {
                          margin-right: 0;
                        }

                        &.civility {
                          width: 110px;
                        }
                      }
                    }

                    .horizontal-dot-separator {
                      margin-bottom: 0;
                    }

                    .actions {
                      display: flex;
                      flex-direction: row;
                      align-items: center;
                      justify-content: flex-end;

                      > p {
                        flex: 1;
                        margin-right: 24px;
                        font-size: 14px;
                        color: $scarlet;
                      }

                      .edit-wrapper {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        cursor: pointer;

                        p {
                          font-size: 14px;
                          color: $rollingStone;
                        }

                        i {
                          margin: 12px;
                          color: $rollingStone;
                        }

                        &:hover {
                          p, i {
                            color: $mountainMeadow;
                          }
                        }

                        &.disabled {
                          cursor: not-allowed;

                          p, i {
                            color: $alto;
                          }

                          &:hover {
                            p, i {
                              color: lighten($alto, 10);
                            }
                          }
                        }
                      }

                      .delete-wrapper {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        cursor: pointer;

                        p {
                          font-size: 14px;
                          color: $rollingStone;
                        }

                        i {
                          margin: 12px;
                          color: $rollingStone;
                        }

                        &:hover {
                          p, i {
                            color: $scarlet;
                          }
                        }

                        &.disabled {
                          cursor: not-allowed;

                          p, i {
                            color: $alto;
                          }

                          &:hover {
                            p, i {
                              color: lighten($alto, 10);
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }

              .error {
                padding: 16px;

                p {
                  color: $scarlet;
                  font-size: 12px;
                  text-align: center;
                }
              }
            }
          }

          .add-project {
            width: 180px;
            height: 40px;
          }
        }

        .school-summary {

          > .title {
            margin-top: 32px;
            margin-bottom: 24px;
            color: $codGray;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 32px;
          }

          #schoolInfo {

            .header {
              display: none;
            }

            .separator:first-of-type {
              display: none;
            }
          }
        }

        .projects-summary {

          > .header {
            display: flex;
            flex-direction: row;
            align-items: center;
            padding-top: 12px;
            padding-bottom: 4px;

            .title {
              color: $codGray;
              font-size: 24px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 32px;
            }

            .subtitle {
              margin-top: 0;
              margin-left: 24px;
              color: $codGray;
              font-size: 13px;
              letter-spacing: 0;
              line-height: 18px;
            }
          }


          ul {

            li {

              .info {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 24px;

                .name {
                  color: $codGray;
                  font-size: 18px;
                  font-weight: bold;
                  letter-spacing: 0;
                  line-height: 24px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis;
                }

                .duration {
                  flex: 1;
                  margin-left: 32px;
                  margin-right: 64px;
                  color: $lynch;
                  font-size: 13px;
                  letter-spacing: 0;
                  line-height: 24px;
                  white-space: nowrap;
                  text-align: right;
                }

                .type {
                  width: 100px;

                  .challenge {
                    height: 40px;

                    img {
                      height: 100%;
                    }
                  }

                  img {
                    width: 100%;
                    height: auto;
                  }
                }
              }

              .users {

                .horizontal-dot-separator {
                  margin: 0;
                }

                .user {
                  display: flex;
                  flex-direction: row;
                  margin: 6px 0;

                  .info-container {
                    width: 33%;

                    .info-label {
                      color: $lynch;
                      font-size: 12px;
                      line-height: 16px;
                    }

                    .info-value {
                      color: $armadillo;
                      font-size: 13px;
                      line-height: 24px;
                      padding-right: 24px;

                      p {
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                      }
                    }
                  }
                }
              }

              .horizontal-plain-separator {
                margin-top: 0;
              }
            }
          }

          .horizontal-plain-separator {
            margin-top: 0;
          }
        }

        .validation-warning {
          margin-top: 32px;
          margin-bottom: 16px;
          color: #000000;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 18px;
          white-space: pre-line;
        }

        > .actions {
          display: flex;
          flex-direction: row;
          margin: 24px 0;
          justify-content: flex-end;

          .button {
            padding: 18px 24px;
          }

          &.validation {
            margin: 60px 0;

            .button {
              flex: 1;
              margin: 0 16px;

              &:first-of-type {
                margin-left: 0;
              }

              &:last-of-type {
                margin-right: 0;
              }
            }
          }
        }

        .lateral-panel {
          display: flex;
          position: fixed;
          top: 0;
          right: 0;
          width: 75%;
          height: 100%;
          transition: z-index;

          &.show {
            transition-delay: 0s;
            z-index: 10;
          }

          &.hide {
            transition-delay: 0.3s;
            z-index: -1;
          }

          > .container {
            display: flex;
            flex-direction: column;
            width: 100%;
            background-color: $white;
            transition: transform 0.3s ease-in;
            box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.1), 0 0 0 1px rgba(145, 145, 145, 0.3);

            &.show {
              transform: translateX(0%);
            }

            &.hide {
              transform: translateX(100%);
            }

            .header {
              position: relative;
              display: flex;
              align-items: center;
              height: 12%;
              width: 100%;
              box-sizing: border-box;
              padding: 30px;
              background-color: $white;
              border-bottom-style: solid;
              border-bottom-color: $athensGray;
              border-bottom-width: 1px;

              .title {
                width: 100%;
                color: $ceruleanLight;
                font-size: 34px;
                letter-spacing: 0;
                line-height: 32px;
              }

              > i {
                position: absolute;
                top: 10px;
                right: 10px;
                color: $bombay;

                &:hover {
                  top: 7px;
                  right: 7px;
                  font-size: 30px;
                  color: $bombay70;
                  cursor: pointer;
                }
              }
            }

            .filter-bar {
              width: 100%;
              height: 10%;
              display: flex;
              flex-direction: row;
              align-items: center;
              background-color: $white;

              .options {
                flex: 1;
                display: flex;
                flex-direction: row;
                padding: 0 24px;

                li {
                  color: $codGray;
                  font-size: 16px;
                  letter-spacing: 0;
                  line-height: 18px;
                  margin: 0 16px;

                  &.selected {
                    font-weight: bold;
                  }

                  &:first-of-type {
                    margin-left: 0;
                  }

                  &:last-of-type {
                    margin-right: 0;
                  }
                }
              }

              .filter {
                margin-right: 24px;
                margin-bottom: 0;
                padding: 0;
                box-shadow: none;
              }
            }

            .user {
              position: relative;
              overflow-y: scroll;
              height: 70%;
              width: 100%;
              background-color: $athensGray;

              .list-wrapper {
                height: 100%;

                .container {
                  height: calc(100% - 56px - 8px);
                  padding: 0;
                  overflow-y: auto;

                }
              }

              .paginate {
                box-shadow: 3px 3px 1px 0 rgba(0, 0, 0, 0.1), 3px 0 1px 0 rgba(145, 145, 145, 0.3);
              }
            }

            .add {
              display: flex;
              align-items: center;
              justify-content: flex-end;
              box-sizing: border-box;
              padding: 30px;
              height: 10%;
              width: 100%;

              .add-button {
                width: 200px;
              }
            }
          }
        }

        .user-item {
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 10px 8px;

          .check {
            width: 24px;
            height: 24px;
            margin-right: 24px;
          }

          .content {
            width: calc(100% - 24px - 24px);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .user-badge {
              display: flex;
              justify-content: center;
              align-items: center;
              color: $white;
              border-radius: 2px;
              min-width: 25px;
              min-height: 25px;
              margin-right: 15px;
              font-size: 16px;

              &.supervisor {
                background-color: $londonHue
              }

              &.mentor {
                background-color: $roseFog
              }
            }

            .identity {
              color: $armadillo;
              font-size: 13px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 24px;
              width: 50%;

              p {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }

            .email {
              flex: 1;
              margin: 0 16px;
              color: $doveGray;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 12px;

              p {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }

            .phone {
              flex: 0;
              color: $doveGray;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 12px;

              p {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                text-align: right;
              }
            }

            i {
              margin-right: 4px;
              color: $bombay;

              &:hover {
                color: darken($bombay, 10);
              }
            }
          }
        }
      }
    }
  }

  .horizontal-dot-separator {
    border: none;
    border-top: 1px dotted $alto;
  }

}
