@import "../../../assets/colors";

#navigation {
  display: flex;
  width: 100%;
  height: 72px;
  overflow: hidden;

  #fixed-wrapper {
    height: 72px;
    width: 100%;
    display: flex;
    position: fixed;
    background-color: $ceruleanLight;
    z-index: 9;
    box-shadow: 0px 0px 5px rgba(148, 105, 105, 0.5);

    .material-icons {
      text-shadow: 0px 2px 3px rgba(0,0,0,.25);
    }

    .main-logo {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 24px;
      cursor: pointer;

      &:hover {
        background-color: $black20;
      }

      > img {
       min-width: 65px;
        height: calc(100% - 30px);
      }
    }

    > img {
      height: calc(100% - 20px);
      margin: 10px 24px;
    }

    > p {
      width: 56px;
      display: flex;
      align-items: center;
      color: $white;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;

    }

    > .items {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      justify-items: center;
      color: $white;

      li {
        display: flex;
        align-items: center;
        padding: 8px 16px;
        margin: 0 8px;
        font-size: 16px;
        cursor: pointer;
        text-shadow: 0px 1px 1px rgba(0,0,0,.25);

        &:hover {
          border-radius: 25px;
          background: $black20;
        }

        &.is-current {
          border-radius: 25px;
          background: $black20;
        }
      }
    }

    > .icons-wrapper {
      display: flex;
      flex-direction: row;

      i {
        width: 72px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        transition: background-color .2s ease-in;
        cursor: pointer;

        &:hover {
          background: $black20;
        }
      }
    }

    #menu {
      display: none;

      i {
        width: 72px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        transition: .2s ease-in background-color;
        cursor: pointer;

        &:hover {
          background-color: $black20;
        }
      }

      .actions {
        height: 100%;
        display: flex;
        flex-direction: row;
        position: absolute;
        right: 0;
      }

      > i {
        position: absolute;


        &.menu {
          left: 0;
        }
      }

      .wrapper {
        width: 320px;
        height: 100%;
        position: fixed;
        top: 72px;
        left: -320px;
        opacity: 0;
        background-color: $ceruleanLight;
        color: $white;
        transition: .3s ease-in all;

        &.displayed {
          border-top: 1px solid rgba(#fff, .3);
          border-right: 1px solid rgba(#fff, .3);
          left: 0;
          opacity: 1;
        }

        > .items {
          margin-left: auto;
          display: flex;
          flex-direction: column;
          color: white;

          li {
            font-size: 18px;
            padding: 0 28px;
            height: 60px;
            display: flex;
            align-items: center;
            cursor: pointer;
            transition: .2s ease-in background-color;

            span {
              width: 24px;
              height: 24px;
              background-color: $white;
              border-radius: 50%;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 11px;
              margin-left: 8px;
            }

            &:hover {
              background-color: $black20;
            }

            &.is-current {
              cursor: initial;
              background-color: $black20;
            }
          }
        }

      }
    }
  }
}

@media screen and(max-width: 1140px) {
  #navigation {
    #fixed-wrapper {
      justify-content: center;

      .main-logo {
        
        img {
          margin-right: 0;
          
        }
      }

      > .icons-wrapper {
        i {
          display: none;
        }
      }

      > p {
        display: flex;
      }

      .items {
        display: none;
        text-shadow: 0px 2px 3px rgba(0,0,0,.2);
      }

      .menu-burger {
        display: flex;
      }

      #menu {
        display: flex;
      }
    }
  }
}
