@import "../../../../assets/colors";

.action-popup {
  max-width: 200px;
  position: absolute;
  top: 0;
  right: 0;
  width: 200px;
  z-index: 10;
  pointer-events: initial;

  .header {
    height: calc(24px + 2 * 10px);
    display: flex;
    align-items: center;
    margin-right: 10px;
    position: relative;

    > p {
      color: $codGray;
      font-size: 13px;
      line-height: 20px;
      margin-left: 28px;
    }

    > i {
      position: absolute;
      right: -5px;
      width: 24px;
      color: $codGray;
      cursor: pointer;
      background-color: $athensGray;
      padding: 5px;
      border-radius: 50%;
      transform-origin: center;

      &:hover {
        color: $alto;
        transform: scale(1.05);
      }
    }
  }

  .items {
    display: flex;
    flex-direction: column;
    background-color: $white;
    box-shadow: 0 0 6px 0 rgba(0,0,0,0.12), 0 6px 6px 0 rgba(0,0,0,0.15), 0 0 12px 0 rgba(0,0,0,0.12), 0 12px 12px 0 rgba(0,0,0,0.15);

    .item {
      min-height: 55px;
      border-bottom: 1px solid $alto;
      cursor: pointer;
      display: flex;
      align-items: center;

      &:hover {
        background-color: $athensGray;
      }

      > p {
        color: $codGray;
        font-size: 15px;
        line-height: 22px;
        font-weight: bold;
        padding: 8px;
      }
    }
  }
}

