@import "../../../../assets/colors";

.school.detail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;


  &.clickable:hover{
    background-color: $athensGray;
    cursor: pointer;
  }

  .name-wrapper {
    flex: 1;
    min-width: 100px;
    margin-right: 24px;

    .address {
      font-size: 12px;
      color: $abbey;
      font-weight: 100;
      min-width: 100px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  p.contact {
    text-align: end;
    padding: 0 10px 0 0;
    font-size: 12px;
    color: $abbey;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .code {
    font-size: 13px;
    color: $codGray;
    font-weight: 600;
    margin-right: 24px;
  }

  .name {
    font-size: 13px;
    color: $codGray;
    font-weight: 600;
    flex: 2;
    min-width: 100px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  i {
    color: $bombay;

    &:hover {
      cursor: pointer;
    }
  }
}
