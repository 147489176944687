// (Colors are named with http://chir.ag/projects/name-that-color/#71B239)
$abbey: #535D5F;
$alto: #D1D1D1;
$armadillo: #433C35;
$bondiBlue: #00A0AF;
$cornflowerBlue: #6195ED;
$doveGray: #696969;
$geyser: #D9DFE5;
$jungleGreen: #31B56F;
$mountainMeadow: #1DB46C;
$nevada: #6D6E71;
$rollingStone: #748285;
$saffron: #f2b843;
$scarlet: #EA1806;
$shark: #27282B;
$sorbus: #FD8204;
$stormDust: #6F6F6E;
$sun: #FDB614;
$wattle: #D0DF5D;
$whiteIce: #EBF8FC;
$wildSand: #F5F5F5;

//Copier coller autre projet EPA
$cerulean: #0093E0; //blue
$cerulean70: rgba(102, 174, 249, 0.7); //blue 70%
$cerulean50: rgba(102, 174, 249, 0.5); //blue 50%
$cerulean10: rgba(102, 174, 249, 0.1); //blue 10%
$ceruleanLight: #00A0AF;
$atlantis: #8CC73F; //apple green
$atlantis70: rgba(140, 199, 63, 0.7); //apple green 70%
$atlantis10: rgba(140, 199, 63, 0.1); //apple green 10%
$treePoppy: #F16122; //orange
$treePoppy70: rgba(247, 148, 30, 0.7); //orange
$treePoppy30: rgba(247, 148, 30, 0.3); //orange
$codGray: #1E1E1E; //text
$codGray60: rgba(30, 30, 30, 0.7);
$lynch: #688197; //label
$bombay: #B1B2BA; //icon
$bombay70: rgba(177, 178, 186, 0.7); //icon
$athensGray: #EFF2F4; //inputText
$dustyGray: #979797;
$mineShaft: #221F1F;
$mystic: #E2E7EB;
$seashell: #F1F1F1;
$white: #FFFFFF;
$white70: rgba(255, 255, 255, 0.7);
$white30: rgba(255, 255, 255, 0.3);
$white10: rgba(255, 255, 255, 0.1);
$black: #000000;
$black20: rgba(0, 0, 0, 0.2);
$red: #E60000; //error
$roseFog: #E7BBB6;
$londonHue: #BD8DBF;
$flamingo: #F05923;
