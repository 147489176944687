@import "../../../assets/colors";

.switch-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  .switch {
    position: relative;
    display: inline-block;
    width: 34px;
    height: 14px;

    input {
      opacity: 0;
      width: 0;
      height: 0;
    }

    .slider {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($color: $mineShaft, $alpha: 0.25);
      transition: 0.4s;
      cursor: pointer;

      &:before {
        content: "";
        position: absolute;
        height: 20px;
        width: 20px;
        left: -3px;
        top: -3px;
        background-color: $seashell;
        transition: 0.4s;
      }

      &.round {
        border-radius: 7px;

        &:before {
          border-radius: 50%;
        }
      }
    }

    input:checked + .slider {
      background-color: $cerulean50;

      &:before {
        background-color: $cerulean;
      }
    }

    input:checked + .slider:before {
      transform: translateX(20px);
    }
  }

  p {
    margin-left: 16px;
    color: $lynch;
    font-size: 13px;
    letter-spacing: 0;
    line-height: 24px;
  }
}
