@import "../../../assets/colors";

.date-input {
  .label {
    color: $lynch;
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
  }

  .wrapper {
    display: flex;

    .input-wrapper {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;

      input {
        width: 164px;
        height: auto;
        padding: 6px 16px;
        border: none;
        outline: none;
        font-size: 13px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 24px;
        background: $mystic;
        box-sizing: border-box;
        -webkit-text-fill-color: $armadillo;
      }

      i {
        position: absolute;
        right: 16px;
        top: 4px;
        color: $armadillo;
        pointer-events: none;
      }
    }
  }
}
