@import "../../../assets/colors";
@import "../../../assets/dimens";

#schools{
  display: flex;
  flex-direction: column;

  .no-list{
    align-self: center;
  }

  .content {
    width: 100%;

    .footer {
      width: 100%;
    }
  }
}
