@import "../../../../assets/colors";
@import "../../../../assets/dimens";
#actionBar {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;

  .action-button {
    display: flex;
    justify-content: center;
    color: $white;
    height: 40px;
    margin-top: 30px;
    width: 172px;

    span {
      font-size: $labelButton;
    }

    i {
      font-size: $iconButton;
      margin-left: 25px;
    }
  }

}
