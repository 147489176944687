@import "../../../../assets/colors";

.user-item {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 0;

  &:hover{
    background-color: $athensGray;
    cursor: pointer;
  }

  &:active{
    background-color: darken($athensGray, 5);
  }

  .user-badge {
    min-width: 24px;
    margin-right: 10px;
    font-size: 10px;
  }

  .identity {
    font-size: 13px;
    color: #433C35;
    width: 30%;
    font-weight: bold;
    overflow: hidden;

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-right: 15px;
    }
  }

  .email {
    font-size: 12px;
    color: #696969;
    width: 50%;
    overflow: hidden;

    p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  .phone {
    font-size: 12px;
    color: #696969;
    text-align: right;
  }

  i {
    width: 4%;
    color: $rollingStone;

    &:hover {
      cursor: pointer;
    }
  }
}

.user-separator {
  width: 100%;
  border: 1px dotted $alto;
  border-right: none;
  border-left: none;
}
