@import "../../../../assets/colors";

#notifications {
  .select-all {
    display: flex;
    padding: 8px 20px;

    .checkbox .label {
      height: 24px;
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $black;
      font-size: 13px;
      letter-spacing: 0;
      line-height: 18px;
    }
  }

  .wrapper-list {
    max-height: 60vh;
  }

  .notif-item {
    display: flex;
    flex-direction: row;
    position: relative;
    width: 100%;
    box-shadow: 0 0 2px 0 rgba($bombay, 1);
    margin: 10px 0;
    background-color: $white;

    > .check {
      display: flex;
      justify-content: center;
      box-sizing: border-box;
      padding: 12px;
      background-color: transparent;
      border-right: 1px solid rgba($bombay, 0.4);

      .checkbox {
        width: 22px;
        height: 22px;
        display: flex;

        .checkmark {
          width: 18px;
          height: 18px;

          &::after {
            left: 6px;
            top: 2px;
          }
        }
      }
    }

    > .content {
      max-width: calc(100% - 48px);
      flex: 1;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      .header {
        display: flex;
        flex-direction: row;
        align-items: center;
        box-sizing: border-box;
        padding: 12px 12px 12px 16px;
        border-bottom: 1px solid rgba($color: $black, $alpha: 0.2);

        .label {
          flex: 1;
          color: $black;
        }

        .options {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
          color: $doveGray;

          &:hover {
            cursor: pointer;
          }
        }
      }

      .info {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        padding: 8px 8px 8px 16px;

        p {
          flex: 1;
          color: $doveGray;
          white-space: nowrap;
          overflow: hidden;

          .recipient {
            margin-left: 60px;
          }
        }

        i {
          flex: 0;
          margin-left: 16px;
          font-size: 20px;
          color: $bondiBlue;
          transition: transform 0.3s ease;
        }
      }

      .content {
        max-height: 0px;
        padding: 0 16px;
        overflow: hidden;
        transition: max-height 0.3s ease;

        .separator {
          width: 100%;
          height: 1px;
          background: rgba($color: $black, $alpha: 0.2);
        }

        p {
          margin: 12px 0;
          line-height: 20px;
          white-space: pre-line;
        }

        .links {
          display: flex;
          flex-direction: row;
          margin-top: 48px;
          margin-bottom: 12px;

          .button {
            padding: 12px 20px;
            flex-direction: row-reverse;
            border: 1px solid $alto;
            background: $white;

            span {
              font-size: 12px;
              line-height: 16px;
            }

            i {
              margin-right: 8px;
              font-size: 16px;
            }

            &:hover {
              background: lighten($alto, 10);
            }
          }
        }
      }

      &.expanded {
        .info {
          p {
            white-space: pre-line;
            overflow: auto;
          }
          
          i {
            transform: rotateZ(90deg);
          }
        }
        .content {
          max-height: 1500px;
        }
      }
    }

    .bold {
      font-weight: bold;
    }

    p {
      color: $codGray;
      font-size: 13px;
      line-height: 16px;
    }
  }
}
