@import "../../../../assets/colors";

#list-item {
  // display: -webkit-box;
  display: flex;
  display: -webkit-box;
  // min-height: 100px;
  flex-direction: row;
  position: relative;
  width: 100%;
  box-shadow: 0 0 2px 0 rgba($bombay, 1);
  margin: 10px 0;
  background-color: $white;

  > .check {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    padding: 15px;
    background-color: transparent;
    border-right: 1px solid rgba($bombay, 0.4);

    .checkbox {
      width: 25px;
      height: 25px;
      display: flex;

      .checkmark {
        width: 18px;
        height: 18px;

        &::after {
          left: 6px;
          top: 2px;
        }
      }
    }
  }

  > .content {
    display: flex;
    flex-direction: column;
    width: calc(100% - 50px);
    overflow: hidden;
    cursor: pointer;

    .header {
      display: flex;
      flex-direction: row;
      height: 60%;
      // width: 100%;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;
      padding: 10px 10px 10px 20px;

      .label {
        box-sizing: border-box;
        z-index: 10;
        background: white;
        // flex: 1;
        flex-shrink: 1;

        p{
          // max-width: 300px;
          // white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          color: rgba($black, .8);
        }
      }

      .info {
        display: flex;
        width: 85%;
        display: flex;
        flex-direction: row;
        // width: 60%;
        // flex: 1;
        justify-content: flex-end;

        .element {
          // max-width: 100%;
          margin: 0 0 0 10px;
          display: flex;

          .place {
            color: $doveGray;
            display: flex;
            height: 100%;
            align-items: center;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            justify-content: flex-end;
          }

          .phone,.date_demande {
            display: flex;
            height: 100%;
            min-width: 130px;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            overflow: hidden;

            i {
              margin-right: 10px;
              color: $bondiBlue;
              font-size: 15px;
            }

            p {
              color: $doveGray;
              font-size: 12px;
            }
          }

          .email {
            display: flex;
            height: 100%;
            flex-direction: row;
            align-items: center;

            i {
              font-size: 15px;
              margin-right: 10px;
              color: $cerulean;
            }

            p {
              color: $doveGray;
              font-size: 12px;  
            }
          }

          .academy {
            display: flex;
            height: 100%;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            
            p {
              // max-width: 100px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              
              &:first-of-type {
                color: $doveGray;
                padding-right: 10px;
              }
            }
          }
        }

        .options{
          display: flex;
          justify-content: center;
          align-items: center;
          margin-left: 10px;
          color: $doveGray;

          &:hover{
            cursor: pointer;
          }
        }
      }
    }

    .footer {
      display: flex;
      background-color: $white;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      border-top: 1px solid rgba($bombay, 0.3);
      box-sizing: border-box;
      padding: 10px 65px 10px 20px;
      width: calc(100% - 50px);

      .members {
        display: flex;
        flex-direction: row;

        .item {
          display: flex;
          flex-direction: row;
          margin-right: 30px;

          .pins {
            display: flex;
            justify-content: center;
            align-items: center;
            min-width: 30px;
            height: 18px;
            border-radius: 2px;
            color: $white;
            margin-right: 10px;
            font-size: 12px;
            &.otther{
              padding: 5px 10px
            }
            &.projects {
              background-color: $wattle;
            }

            &.supervisors {
              background-color: $mountainMeadow;
            }

            &.mentors {
              background-color: $sun;
            }

            &.facilitators {
              background-color: $bondiBlue;
            }

            &.schools {
              background-color: $flamingo;
            }
          }
          
          .member-poste {
            line-height: 20px;
          }

          p {
            color: $doveGray;
          }
        }
      }

      .status {
        display: flex;
        flex-direction: row;
        align-items: center;
        position: absolute;
        right: 10px;
        bottom: 10px;

        p {
          color: $doveGray;
          &:first-of-type {
            margin-right: 10px;
          }
        }

        .state {
          height: 15px;
          width: 15px;
          background-clip: content-box;
          border: 1px solid $bombay;
          border-radius: 15px;
          padding: 2px;

          &.pending, &.warning {
            background-color: $treePoppy;
          }

          &.valid, &.waiting, &.ok {
            background-color: $atlantis;
          }

          &.draft, &.deny {
            background-color: $red;
          }
        }
      }
    }
  }

  .bold {
    font-weight: bold;
  }

  p {
    color: $codGray;
    font-size: 13px;
  }
}

@media screen and (max-width: 1180px) {
  #list-item {
    .content{
      .header {
        .label {
          padding: 0;
          // flex:1;

          p{
            // max-width: 300px;
            // white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
        .info {
          // flex:1;
          .element {
            .place {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              }
          }
        } 
      }
    }
  }
}


@media screen and (max-width: 800px) {
  #list-item {
    .content{
      padding: 20px 0;
      .header {
        height: initial;
        padding: 20px;
        justify-content: center;
        flex-wrap: wrap;
        .label {
          width: initial;
          padding: 0;
          
          p{
            margin-right: 0;
            text-align: center;
            // max-width: initial;
            // white-space: nowrap;
            overflow: hidden;
            text-overflow: initial;
            font-size: 16px;
          }
        }
        .info {
          width: initial;
          flex-wrap: wrap;
          justify-content: center;

          .element {
            margin: 10px 20px;
            display: flex;
            justify-content: center;
            text-align: center;

            .place {
              align-items: center;
              min-width: initial;
              


              p{
                max-width: 30px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
              }
            }

            .email {
              min-width: initial;;
            }

            .phone {
              min-width: initial;;
            }

            .academy {
              width: initial;
            }
          }

          .options {
            position: absolute;
            top: 10px;
            right: 10px;
          }
        } 
      }
      .footer {
        justify-content: center;
        padding: 10px;
        .members {
          flex-wrap: wrap;
          justify-content: center;
          margin-right: 50px;

          .item {
            width: 120px;
            margin: 5px 20px;
          
          }
        }

        .status {
          position: absolute;
          bottom: 10px;
          right: 10px;
        }
      }
    }
  }
}
