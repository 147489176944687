@import '../../../../assets/colors';

.form-drop-down-wrapper {
  display: flex;
  flex-direction: column;

  > .label {
    color: $lynch;
    font-size: 12px;
    margin-bottom: 4px;
  }

  .form-drop-down {
    position: relative;
    display: flex;
    flex-direction: column;

    .box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      height: 22px;
      cursor: pointer;
      padding: 16px;
      box-sizing: border-box;
      border: none;
      border-bottom: 1px solid $white;
      background-color: $athensGray;

      .current {
        color: $armadillo;
        font-size: 13px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 600;
        margin-right: 5px;
      }

      .arrow {
        max-width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid $bombay;
        transition: transform 0.2s ease-in;
        display: flex;

        &.up {
          transform: rotate(180deg);
        }
      }

      &.disabled {
        cursor: auto;
        background-color: transparent;
        padding: 0;
        padding-bottom: 10px;
        border-bottom: 1px solid $black20;

        .arrow {
          display: none;
        }
      }

      &.invalid {
        // border-bottom: 2px solid $scarlet;
        border-bottom: 2px solid $treePoppy;
      }
    }

    > .error {
      color: $whiteIce;
      background-color: $treePoppy;
      font-size: 14px;
      padding: 0.5rem;
      text-align: center;
      border-radius: 0 0 5px 5px;
    }

    .list {
      position: absolute;
      right: 0;
      left: 0;
      max-height: 250px;
      overflow-y: scroll;
      top: 38px;
      flex-direction: column;
      background-color: $white;
      opacity: 0;
      z-index: -1;
      place-content: flex-start;
      transition: opacity 0.2s ease-in;
      box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.08),
        0 1px 2px 0 rgba(0, 0, 0, 0.08), 0 1px 2px 0 rgba(0, 0, 0, 0.1);

      .row {
        display: flex;
        align-items: center;
        min-height: 32px;
        width: 100%;
        box-sizing: border-box;
        padding: 8px 12px;
        transition: background-color 0.2s ease-in;

        p {
          color: $armadillo;
          font-size: 13px;
        }

        &:hover {
          background-color: $athensGray;
          cursor: pointer;
        }

        &:last-child {
          border-bottom: none;
        }
      }

      &.display {
        opacity: 1;
        z-index: 10;
      }
    }
  }
}
