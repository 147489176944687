@import './assets/colors';

@font-face {
  font-family: 'BlogScriptW00-Regular';
  src: url('//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.eot');
  src: url('//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.woff2')
      format('woff2'),
    url('//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.woff')
      format('woff'),
    url('//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.ttf')
      format('truetype'),
    url('//db.onlinewebfonts.com/t/1412bea64d9ba801365a8a4b8c6ee782.svg#BlogScriptW00-Regular')
      format('svg');
}

* {
  margin: 0;
  padding: 0;
  font-family: 'Ubuntu', sans-serif;
}

*:not(input):not(textarea) {
  user-select: none;
}

body {
  background-color: #f5f5f5;
  overflow: hidden;
}

#root {
  height: 100vh;
  overflow-y: auto;
}

#root::-webkit-scrollbar {
  width: 0.5em;
}

#root::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

#root::-webkit-scrollbar-thumb {
  background-color: #00717c;
  outline: 1px solid slategrey;
  border-radius: 10px;
}

.horizontal-plain-separator {
  border: 1px solid #eff2f4;
  border-right: none;
  border-left: none;
  margin: 20px 0;
}

.horizontal-dot-separator {
  border: 1px dotted #eff2f4;
  border-right: none;
  border-left: none;
  margin: 20px 0;
}

input,
textarea {
  -webkit-text-fill-color: #433c35;
}

ul {
  list-style: none;
}

.fade {
  animation-name: fade;
  animation-duration: 0.5s;
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
}

.mini-icon-container {
  display: flex;
  flex-direction: column;

  img {
    object-fit: contain;
  }

  p {
    color: $saffron !important;
    font-size: 12px !important;
    text-transform: uppercase;
    text-align: center;
    font-family: 'BlogScriptW00-Regular', serif;
  }
}
