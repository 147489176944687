#schoolsList {
  .title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .name {
      font-size: 24px;
    }
  }

  .separator {
    border: 1px dotted #D1D1D1;
    border-right: none;
    border-left: none;
  }

  > .separator {
    margin-top: 20px;
  }
  
  .no-school-msg {
    text-align: center;
    margin-top: 35px;
  }
}
