@import "../../../assets/colors";

#inscriptions {
  display: flex;
  flex-direction: column;

  .secondary-filter {
    display: flex;
    flex-direction: row;
    padding: 12px 8px 16px;

    p {
      color: $codGray;
      font-size: 16px;
      letter-spacing: 0;
      line-height: 18px;
      margin: 0 16px;

      &.selected {
        font-weight: bold;
      }

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-right: 0;
      }

      &:hover {
        cursor: pointer;
        color: lighten($codGray, 20);
      }
    }
  }

  .schools {
    .content {
      width: 100%;

      .footer {
        width: 100%;
      }
    }
  }

  .mentors {

    .header, .footer {
      width: calc(100% - 7px);
    }

  }
}
