#user-details-screen  {
  display: flex;
  flex-direction: row;
  justify-content: center;

  .side-bar { width: 25% }
  .user-details { width: 75% }

  .user-details-wrapper {
    width: 100%;
  }
}

@media screen and (max-width: 940px) {
  #user-details-screen {
    flex-direction: column;

    .side-bar { display: none }
    .user-details { width: 100% }
  }
}
