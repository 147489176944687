@import "../../../../assets/colors";
@import "../../../../assets/dimens";

#projectInfo {
  > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 24px;

    .edit-button {
      margin: 0 0 0 20px;
    }

    .title {
      input {
        height: auto;
        font-size: 24px;
      }
    }
  }

  > .type {
    p {
      font-size: $labelButton;
      color: $lynch;
    }

    .list-radio {
      display: flex;
      flex-direction: row;
      margin-top: 10px;
      width: 100%;
      flex-wrap: wrap;
      row-gap: 15px;
    }
  }

  > .pass,
  > .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  > .duration {
    .form-drop-down-wrapper {
      .box.disabled {
        border-bottom: none;
      }
    }
  }

  .medium {
    width: 30%;
    margin: 20px 0;
  }
}
