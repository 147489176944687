@import "../../../assets/colors";

.input-wrapper {
  display: flex;
  flex-direction: column;

  > .label {
    color: $lynch;
    font-size: 12px;
    margin-bottom: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      font-size: 10px;
      margin-left: 4px;

      &.half {
        color: $sun;
      }

      &.full {
        color: $red;
      }
    }
  }

  .container {
    display: flex;
    flex-direction: row;
    position: relative;

    input {
      height: 22px;
      width: 100%;
      padding: 16px;
      font-size: 13px;
      box-sizing: border-box;
      border: none;
      border-bottom: 1px solid $white;
      font-weight: 600;
      background-color: $athensGray;
      color: $armadillo;
      overflow: hidden;
      text-overflow: ellipsis;

      &:focus {
        outline: none;
      }

      &.invalid {
        border-bottom: 2px solid $scarlet;
      }

      &.highlight {
        &:disabled {
          background-color: transparent;
          padding-bottom: 5px;
          border-bottom: 1px solid $black20;
        }
      }

      &:disabled {
        background-color: transparent;
        padding: 0;
      }
    }

    i {
      position: absolute;
      right: 16px;
      top: 4px;
      color: $armadillo;
      pointer-events: none;
    }

    img {
      margin-right: 8px;
      color: $armadillo;
    }
  }

  > .error {
    color: $scarlet;
    font-size: 12px;
  }
}

@media screen and (max-width: 800px) {
  .input-wrapper {
    > .label {
      margin-bottom: 8px;
    }

    input {
      &.highlight {
        &:disabled {
          background-color: transparent;
          padding-bottom: 10px;
          border-bottom: 1px solid $black20;
        }
      }
    }
  }

  .input-wrapper:last-of-type {
    margin-bottom: 10px;
  }
}
