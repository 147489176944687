@import "../../../../assets/colors";
@import "../../../../assets/dimens";

#mini-detail {
  max-width: 1280px;
  min-height: calc(100% - 72px + 12px);
  display: flex;
  flex-direction: row;
  margin: auto;
  padding: 24px 55px;
  box-sizing: border-box;

  .side-bar {
    width: 25%;
    display: flex;
    flex-direction: row;
    position: relative;

    .sidebar-wrapper {
      display: flex;
      flex-direction: column;
      position: fixed;

      .back-link {
        display: flex;
        flex-direction: row;
        align-items: center;
        white-space: nowrap;

        i {
          color: $rollingStone;
          margin-right: 15px;
        }

        .link {
          font-size: 13px;
          color: #000;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .panel.sidebar {
        margin-top: 32px;
        padding: 26px 44px 36px 22px;

        .menu li {
          color: #000000;
          font-size: 13px;
          letter-spacing: 0;
          line-height: 18px;
          margin-bottom: 16px;
          cursor: pointer;

          &.selected {
            font-weight: bold;
          }

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }

      .actions {
        .button {
          margin-top: 10px;
        }
      }
    }
  }

  .mini {
    width: 75%;

    .panel {
      padding: 50px 100px;
      margin-bottom: 16px;
    }

    .section > .label,
    .section > .line > .label {
      color: $codGray;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 24px;
    }

    .line {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .column {
      display: flex;
      flex-direction: column;
    }

    .separator {
      width: 100%;
      height: 1px;
      background: $dustyGray;
    }

    .dashed-separator {
      width: 100%;
      height: 1px;
      border-bottom: 1px dotted $dustyGray;
    }

    .input-wrapper .container {
      input {
        height: auto;
        padding: 6px 16px;
        font-size: 13px;
        letter-spacing: 0;
        line-height: 24px;
        font-weight: 500;
        background-color: $mystic;

        &:disabled {
          -webkit-text-fill-color: $lynch;
        }
      }

      i {
        top: 6px;
      }
    }

    .actions {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-top: 18px;

      .button {
        width: 180px;
      }
    }

    .base-info {
      .name {
        input {
          padding: 12px 16px;
          color: $codGray;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 32px;
        }
      }

      .line {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-top: 14px;

        img {
          height: 40px;
        }

        .status {
          width: 250px;
        }
      }
    }

    .advanced-info {
      .types {
        display: flex;
        flex-direction: row;
        margin-bottom: 20px;

        .radio-wrapper {
          margin-left: 100px;

          label {
            display: flex;
            flex-direction: column;
            justify-content: center;
            font-weight: 300;
            color: black;

            &.checked {
              font-weight: bold;
            }
          }
        }
      }

      > .dates {
        display: flex;
        flex-direction: row;
        margin-bottom: 28px;

        .label {
          margin-top: 16px;
        }

        > .wrapper {
          display: flex;
          flex-direction: column;
          margin-left: 72px;

          .dates {
            display: flex;
            flex-direction: row;

            > div:first-of-type {
              margin-right: 36px;
            }
          }

          .duration-wrapper {
            width: 164px;

            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
              margin: 0;
            }

            input[type="number"] {
              -moz-appearance: textfield;
            }
          }
        }
      }

      .partners {
        margin-top: 18px;
        margin-bottom: 28px;

        .header {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .label {
            color: $codGray;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
          }

          .button {
            width: 100px;
            padding: 8px 12px 8px 16px;
            color: white;

            span {
              font-size: 12px;
            }
          }
        }

        .list {
          .partner {
            max-width: 530px;

            .delete {
              margin-left: 32px;
              align-self: center;
              cursor: pointer;
            }

            .line {
              display: flex;
              flex-direction: row;
              margin-bottom: 10px;

              .group:last-of-type {
                .input-wrapper {
                  margin-left: 30px;
                }
              }
            }

            .group {
              width: 50%;
              display: flex;
              flex-direction: row;

              .input-wrapper {
                width: 100%;
              }

              .name {
                margin-top: 16px;
                margin-bottom: 28px;
              }

              .form-drop-down-wrapper {
                margin-right: 22px;

                .form-drop-down {
                  width: 110px;

                  .box {
                    height: 37px;
                    background-color: $mystic;

                    .current {
                      padding: 6px 0;
                      font-size: 13px;
                      letter-spacing: 0;
                      line-height: 24px;
                      font-weight: 500;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .subject {
        margin: 24px 0;

        .text-area-wrapper {
          margin-top: 20px;

          .label {
            display: none;
          }
        }
      }

      .location {
        margin-top: 20px;
        margin-bottom: 28px;

        .line {
          display: flex;
          flex-direction: row;
          margin-top: 16px;

          > div {
            flex: 11;
            margin-left: 16px;
            margin-right: 16px;

            &:first-child {
              flex: 10;
              margin-left: 0;
            }

            &:last-child {
              flex: 8;
              margin-right: 0;
            }
          }
        }
      }
    }

    .schools {
      > .header {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;

        .name {
          color: $codGray;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 32px;
        }

        .title-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          p {
            color: $codGray;
            font-size: 24px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 32px;
          }

          .button {
            width: 100px;
            padding: 8px 12px 8px 16px;
            color: white;

            span {
              font-size: 12px;
            }
          }
        }
      }

      .lateral-menu {
        .panel.filter {
          margin-bottom: 20px;
          padding: 18px 13px 18px 13px;
          box-shadow: none;
        }
      }

      .list {
        .school {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;

          .school-info {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-top: 16px;
            padding-bottom: 32px;

            .line {
              width: 100%;
              display: flex;
              flex-direction: row;
            }

            .name {
              flex: 1;
              color: black;
              font-size: 13px;
              font-weight: 500;
              letter-spacing: 0;
              line-height: 16px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
            }

            i {
              padding: 2px;
              border-radius: 50%;
              font-size: 16px;
              color: white;
              background-color: $treePoppy;
            }

            .address {
              flex: 1;
              color: $abbey;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 12px;
            }

            .phone {
              margin-right: 50px;
              color: $doveGray;
              font-size: 12px;
              letter-spacing: 0;
              line-height: 12px;
            }
          }

          .add-group {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;

            .button {
              width: 180px;
              height: 40px;
              padding: 8px 12px 8px 16px;
              color: white;

              span {
                font-size: 12px;
              }

              i {
                color: white;
              }
            }
          }

          .groups {
            width: 100%;

            .group {
              width: 100%;

              > .header {
                display: flex;
                flex-direction: row;
                align-items: center;
                padding-top: 20px;
                padding-bottom: 12px;

                > .label {
                  flex: 1;
                  color: $codGray;
                  font-size: 18px;
                  font-weight: bold;
                  letter-spacing: 0;
                  line-height: 24px;
                }

                i {
                  padding: 2px;
                  border-radius: 50%;
                  font-size: 16px;
                  color: white;
                  background-color: $treePoppy;
                  cursor: pointer;
                }
              }

              .wrapper {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 8px;
                padding: 8px 22px;
                background: $wildSand;

                &:last-of-type {
                  margin-bottom: 12px;
                }

                .label {
                  width: 150px;
                  color: $lynch;
                  font-size: 13px;
                  letter-spacing: 0;
                  line-height: 24px;
                }

                .drop-down {
                  flex: 1;
                  margin: 0;
                  border: 1px solid $geyser;
                  border-radius: 2px;
                  background-color: white;
                }

                &.wrapper-composition {
                  justify-content: space-between;

                  .composition {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    .input-wrapper {
                      .label {
                        display: none;
                      }

                      .container {
                        width: 120px;
                        border: 1px solid $geyser;
                        border-radius: 2px;
                        background-color: white;

                        input {
                          background: transparent;
                        }

                        input::-webkit-outer-spin-button,
                        input::-webkit-inner-spin-button {
                          -webkit-appearance: none;
                          margin: 0;
                        }

                        input[type="number"] {
                          -moz-appearance: textfield;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }

      .students {
        flex: 1;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        margin: 22px 0;

        .label {
          margin-right: 12px;
          color: $codGray;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 24px;
        }

        .value {
          color: $atlantis;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 32px;
        }
      }
    }

    .team {
      > .header {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;

        .name {
          color: $codGray;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 32px;
        }

        .title-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          p {
            color: $codGray;
            font-size: 24px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 32px;
          }
        }
      }

      .line {
        display: flex;
        flex-direction: row;
      }

      .section {
        .line {
          margin-top: 24px;
          margin-bottom: 12px;

          .label {
            flex: 1;
          }

          .button {
            width: 100px;
            padding: 8px 12px 8px 16px;
            color: white;

            span {
              font-size: 12px;
            }
          }
        }
      }

      .lateral-menu .panel.filter {
        padding: 18px 13px;
      }

      .user {
        display: flex;
        flex-direction: column;

        .content {
          display: flex;
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          padding: 10px 0;

          .user-badge {
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white;
            border-radius: 2px;
            min-width: 25px;
            min-height: 25px;
            margin-right: 15px;
            font-size: $labelButton;

            &.supervisor {
              background-color: $londonHue;
            }

            &.mentor {
              background-color: $roseFog;
            }

            &.facilitator {
              background-color: $cerulean;
            }

            &.admin {
              background-color: $cerulean;
            }
          }

          .identity {
            font-size: 13px;
            color: $codGray;
            width: 40%;
            font-weight: bold;
            overflow: hidden;

            p {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
              margin-right: 12px;
              margin-left: 16px;
            }
          }

          .email {
            font-size: 12px;
            color: $codGray;
            width: 40%;
            overflow: hidden;

            p {
              text-overflow: ellipsis;
              overflow: hidden;
              white-space: nowrap;
            }
          }

          .phone {
            font-size: 12px;
            color: $codGray;
            width: 20%;
            text-align: right;
            padding-right: 20px;
          }

          i {
            margin-right: 4px;
            color: $rollingStone;
            cursor: pointer;
          }
        }
      }

      .school {
        .info {
          flex: 1;
          display: flex;
          flex-direction: column;

          .name {
            flex: 1;
            color: black;
            font-size: 13px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 16px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .address {
            flex: 1;
            color: $abbey;
            font-size: 12px;
            letter-spacing: 0;
            line-height: 12px;
          }
        }
      }
    }

    .successes {
      > .header {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;

        .name {
          color: $codGray;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 32px;
        }

        .title-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          p {
            color: $codGray;
            font-size: 24px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 32px;
          }
        }
      }

      .list {
        display: flex;
        flex-direction: column;
        margin-top: 8px;

        .button-wrapper {
          display: flex;
          flex-direction: column;
          margin: 16px 0;

          .button {
            width: 100px;
            padding: 8px 12px 8px 16px;
            margin-bottom: 12px;
            color: white;

            span {
              font-size: 12px;
            }

            &.add-default {
              width: 220px;
            }
          }
        }

        .success {
          width: 100%;
          display: flex;
          flex-direction: row;
          align-items: center;
          margin-top: 8px;
          padding: 6px 18px;
          background: $wildSand;
          box-sizing: border-box;

          .drag {
            color: $rollingStone;
          }

          .input-wrapper {
            flex: 1;
            margin-left: 18px;
            margin-right: 12px;

            input {
              border: 1px solid $geyser;
              border-radius: 2px;
              background-color: white;
            }
          }

          .delete {
            padding: 2px;
            border-radius: 50%;
            font-size: 16px;
            color: white;
            background-color: $treePoppy;
            cursor: pointer;
          }
        }
      }
    }

    .tools {
      > .header {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;

        .name {
          color: $codGray;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 32px;
        }

        .title-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          p {
            color: $codGray;
            font-size: 24px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 32px;
          }
        }
      }

      .tool-type {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        &:first-of-type {
          margin-top: 0;
        }

        > .label {
          color: $codGray;
          font-size: 24px;
          font-weight: 300;
          letter-spacing: 0;
          line-height: 32px;
          margin: 26px 0 16px 0;
        }

        .button-wrapper {
          display: flex;
          flex-direction: row;
          margin: 18px;

          .button {
            width: 166px;
            padding: 8px 12px 8px 16px;
            color: white;

            span {
              font-size: 12px;
            }
          }
        }

        .tools {
          .tool {
            display: flex;
            flex-direction: row;
            padding: 8px 20px 8px 12px;
            margin: 8px 0;
            background: $wildSand;

            .column {
              display: flex;
              flex-direction: column;

              .icon {
                display: flex;
                flex-direction: row;
                align-items: center;
                border: 1px solid $geyser;
                border-radius: 2px;
                background-color: white;

                &.invalid {
                  border: 1px solid $red;
                }

                &.enabled {
                  cursor: pointer;
                }

                .icon-wrapper {
                  width: 48px;
                  height: 48px;
                  display: flex;
                  flex-direction: row;
                  justify-content: center;
                  align-items: center;

                  i,
                  img {
                    font-size: 20px;
                    color: $bondiBlue;
                  }
                }

                .arrow {
                  padding: 12px;
                  color: $lynch;
                }
              }

              .delete {
                width: 20px;
                margin-top: 12px;
                padding: 2px;
                align-self: center;
                border-radius: 50%;
                font-size: 20px;
                color: white;
                background-color: $treePoppy;
                cursor: pointer;
              }

              .test {
                width: 64px;
                height: 28px;
                margin-top: 28px;
              }

              &.content {
                flex: 1;
                margin-left: 8px;
                margin-right: 18px;

                .input-wrapper {
                  height: 48px;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  margin-bottom: 8px;
                  border: 1px solid $geyser;
                  border-radius: 2px;
                  background-color: white;

                  &.invalid {
                    border: 1px solid $red;
                  }

                  &:last-of-type {
                    margin-bottom: 0;
                  }

                  input {
                    background: transparent;
                  }
                }
              }
            }
          }
        }
      }

      .icon-picker-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: $black20;

        .icon-picker {
          max-width: 80%;
          max-height: 80%;
          padding: 32px;
          box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2),
            0 4px 16px 0 rgba(104, 129, 151, 0.15),
            0 8px 26px 0 rgba(0, 0, 0, 0.15);
          background: white;
          z-index: 50;

          .title {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-bottom: 24px;

            p {
              flex: 1;
              margin-left: 20px;
              text-align: center;
            }

            i {
              padding: 2px;
              border-radius: 50%;
              font-size: 24px;
              color: white;
              background-color: $treePoppy;
              cursor: pointer;
            }
          }

          .icon-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            .icon {
              width: 80px;
              height: 80px;
              display: flex;
              flex-direction: column;
              align-items: center;
              margin: 6px;
              padding: 16px;
              border-radius: 3px;
              border: 1px solid rgba(0, 0, 0, 0.08);
              cursor: pointer;
              box-sizing: border-box;

              @keyframes icon {
                0% {
                  transform: skewX(0deg);
                }
                10% {
                  transform: skewX(-10deg);
                }
                20% {
                  transform: skewX(10deg);
                }
                25% {
                  transform: skewX(-8deg);
                }
                30% {
                  transform: skewX(8deg);
                }
                33% {
                  transform: skewX(-4deg);
                }
                36% {
                  transform: skewX(4deg);
                }
                40% {
                  transform: skewX(0deg);
                }
              }

              i,
              .social-icon {
                font-size: 32px;
                margin-bottom: 5px;
                color: $bondiBlue;

                &:hover {
                  color: $bondiBlue;
                  animation-name: icon;
                  animation-duration: 1s;
                }
              }

              img {
                width: 36px !important;
                height: 32px !important;
              }

              p {
                margin-top: 3px;
                font-size: 10px;
                white-space: nowrap;
              }
            }
          }
        }
      }
    }

    .rooms {
      > .header {
        display: flex;
        flex-direction: column;
        margin-bottom: 12px;

        .name {
          color: $codGray;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 32px;
        }

        .title-wrapper {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          p {
            color: $codGray;
            font-size: 24px;
            font-weight: 300;
            letter-spacing: 0;
            line-height: 32px;
          }
        }
      }

      .dispatch-wrapper {
        margin-top: 20px;
        padding: 0 20px;
        overflow: scroll;

        .dispatch {
          > .rooms {
            min-height: 36px;
            margin-left: 100px;

            .room {
              .label {
                width: 40px;
                margin: 6px 12px;
                color: $codGray;
                font-size: 18px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 24px;
                text-align: center;
              }

              &:first-of-type {
                .label {
                  margin-left: 0;
                }
              }

              &:last-of-type {
                .label {
                  margin-right: 0;
                }
              }
            }
          }

          .schools {
            .school {
              margin-top: 8px;

              .label {
                margin: 10px 0;
                color: black;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 16px;
              }

              .groups {
                .group {
                  border-bottom: 1px solid $alto;

                  .label {
                    width: 100px;
                    min-width: 100px;
                    color: $lynch;
                    font-size: 13px;
                    letter-spacing: 0;
                    line-height: 24px;
                  }

                  .rooms {
                    .room {
                      margin: 0 12px;

                      .label {
                        display: none;
                      }

                      input {
                        width: 40px;
                        height: 36px;
                        padding: 0;
                        text-align: center;
                      }

                      input::-webkit-outer-spin-button,
                      input::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                        margin: 0;
                      }

                      input[type="number"] {
                        -moz-appearance: textfield;
                      }

                      &:first-of-type {
                        margin-left: 0;
                      }

                      &:last-of-type {
                        margin-right: 0;
                      }
                    }
                  }

                  .summary {
                    flex: 1;
                    justify-content: flex-end;
                    padding-right: 20px;

                    .total {
                      width: 32px;
                      margin-left: 24px;
                      margin-right: 40px;
                      text-align: center;
                    }

                    .total,
                    .remaining {
                      color: $armadillo;
                      font-size: 13px;
                      font-weight: 500;
                      letter-spacing: 0;
                      line-height: 24px;
                    }

                    .remaining span {
                      color: $treePoppy;
                    }
                  }
                }
              }
            }
          }

          .totals {
            min-height: 36px;
            margin-left: 100px;

            .total {
              .label {
                width: 40px;
                margin: 6px 12px;
                color: black;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 32px;
                text-align: center;
              }

              &:first-of-type {
                .label {
                  margin-left: 0;
                }
              }

              &:last-of-type {
                .label {
                  margin-right: 0;
                }
              }
            }

            .summary {
              flex: 1;
              justify-content: flex-end;
              padding-right: 20px;

              .total {
                width: 24px;
                margin-left: 12px;
                margin-right: 40px;
                text-align: center;
                color: black;
                font-size: 20px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 32px;
                text-align: center;
              }

              .remaining {
                color: $armadillo;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 24px;

                span {
                  color: $treePoppy;
                }
              }
            }
          }
        }
      }

      .students {
        flex: 1;
        padding: 12px 0 14px 0;

        .label {
          color: $codGray;
          font-size: 18px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 24px;
        }

        .value {
          margin-top: 12px;
          color: $atlantis;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 0;
          line-height: 32px;
        }
      }

      .column {
        display: flex;
        flex-direction: column;
      }

      .row {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .summary {
        .rooms {
          .label {
            padding: 12px 20px 8px 20px;
            color: $codGray;
            font-size: 18px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 24px;
            border-left: 1px solid $dustyGray;
            border-right: 1px solid $dustyGray;
          }

          .value {
            padding: 8px 0 10px 0;
            color: black;
            font-size: 24px;
            font-weight: bold;
            letter-spacing: 0;
            line-height: 32px;
            text-align: center;
            background-color: $mystic;
          }
        }
      }

      .progress {
        padding-left: 24px;

        p {
          color: $armadillo;
          font-family: Ubuntu;
          font-size: 13px;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 24px;
        }

        .switch-wrapper {
          margin-left: 36px;
          > p {
            display: none;
          }
        }
      }

      .list {
        .room {
          margin: 16px 0;
          background: $wildSand;

          .summary {
            padding: 20px;

            .label {
              color: $codGray;
              font-size: 18px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 24px;

              &:first-of-type {
                flex: 1;
              }
            }

            .value {
              margin: 0 22px;
              color: $atlantis;
              font-size: 24px;
              font-weight: bold;
              letter-spacing: 0;
              line-height: 32px;
            }

            .delete {
              padding: 2px;
              border-radius: 50%;
              font-size: 20px;
              color: white;
              background-color: $treePoppy;
              cursor: pointer;
            }
          }

          .tools {
            padding: 0 20px;

            &.displayed {
              > .line > i {
                transform: rotate(90deg);
              }

              .list {
                max-height: 1500px;
              }
            }

            > .line {
              padding: 20px 0 16px;
              cursor: pointer;

              > .label {
                flex: 1;
                color: $codGray;
                font-size: 13px;
                font-weight: bold;
                letter-spacing: 0;
                line-height: 24px;
              }

              i {
                font-size: 18px;
                color: $rollingStone;
                transition: transform 0.3s ease;
              }
            }

            .list {
              max-height: 0;
              overflow: hidden;
              transition: max-height 0.3s ease;
            }

            .tool {
              padding: 12px 16px;

              .icon-wrapper {
                width: 24px;
                display: flex;
                flex-direction: row;
                justify-content: center;
                margin-right: 16px;

                i,
                img {
                  font-size: 20px;
                  color: $bondiBlue;
                }
              }

              .label {
                flex: 1;
                color: $armadillo;
                font-size: 13px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 24px;
              }

              .input-wrapper {
                width: 50%;
                height: 48px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border: 1px solid $geyser;
                border-radius: 2px;
                background-color: white;

                &.invalid {
                  border: 1px solid $red;
                }

                .label {
                  display: none;
                }

                input {
                  background: transparent;
                }
              }

              .test {
                width: 64px;
                height: 28px;
                margin-left: 14px;
              }
            }
          }

          .footer {
            padding: 20px 28px;

            .link {
              flex: 1;
            }

            .link p, .password p {
              user-select: text;
            }

            a,
            p {
              color: black;
              font-size: 13px;
              letter-spacing: 0;
              line-height: 18px;
              text-decoration: none;
            }

            i {
              margin-right: 20px;
              font-size: 20px;
              color: $jungleGreen;
            }
          }
        }
      }

      .button-wrapper {
        display: flex;
        flex-direction: row;
        margin: 16px 0;

        .button {
          width: 166px;
          padding: 8px 12px 8px 16px;
          color: white;

          span {
            font-size: 12px;
          }
        }
      }
    }
  }

  .popup-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $black20;

    .popup {
      max-width: 80%;
      max-height: 80%;
      padding: 32px;
      box-shadow: 0 3px 4px 0 rgba(10, 15, 44, 0.2),
        0 4px 16px 0 rgba(104, 129, 151, 0.15), 0 8px 26px 0 rgba(0, 0, 0, 0.15);
      background: white;
      z-index: 50;

      .title {
        margin-bottom: 24px;
        font-size: 24px;
        font-weight: bold;
        text-align: center;
      }

      .messages {

        .main {
          text-align: center;
        }
        margin-bottom: 40px;
      }

      .actions {
        display: flex;
        flex-direction: row;

        .button {
          flex: 1;
          margin-left: 16px;
          margin-right: 16px;

          &:first-child {
            margin-left: 0;
          }

          &:first-right {
            margin-left: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  #mini-detail .mini .schools .list .school {
    .groups .group .wrapper {
      .label {
        width: 130px;
      }

      &.wrapper-composition {
        flex-direction: column;

        .composition {
          width: 100%;

          &:last-of-type {
            margin-top: 10px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 900px) {
  #mini-detail .mini .schools .list .school {
    .add-group {
      flex-direction: column;
      margin-top: 20px;

      .button-wrapper {
        align-self: flex-start;
      }

      .students {
        align-self: flex-end;
      }
    }
  }
}
