@import "../../../../assets/colors";

.wrapper-list {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 67vh;

  >.container {
    width: 100%;
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    z-index: 1;
    padding: 0 8px 8px 8px;
    margin-bottom: 8px;

    

    .no-list{
      align-self: center;
    }
  }

  .container::-webkit-scrollbar {
    width: .5em;
  }
    
  .container::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
    border-radius: 10px;
  }
  
  .container::-webkit-scrollbar-thumb {
    background-color: $bondiBlue;
    outline: 1px solid slategrey;
    border-radius: 10px;
  }

  > .paginate {
    height: 56px;
    width: calc(100% - 14px);
    padding: 10px;
    background-color: $white;
    box-sizing: border-box;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1), 0 2px 3px 0 rgba(0, 0, 0, 0.12), 0 0 2px 0 rgba(0, 0, 0, 0.2);
    z-index: 2;
  }
}


