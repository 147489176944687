@import '../../../../assets/colors.scss';

.filter {
  max-width: none;
  padding: 18px 13px 18px 13px;

  > .content {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    height: 100%;
    justify-content: space-between;

    .filters {
      display: flex;
      justify-content: flex-end;
      // align-items: center;
      width: 80%;
      .filters_search {
        // align-items: center;
        display: flex;

        flex-direction: column;
        justify-content: flex-start;
        width: 45%;
        .filter-label {
          font-size: 14px;
          color: $stormDust;
          padding-left: 6px;
        }
      }
      .filters_dropdown {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-end;
      }
    }

    .tabs {
      display: flex;
      height: 100%;
      width: 20%;
      margin-left: 40px;

      .title {
        display: flex;
        align-items: center;
        flex-direction: row;
        height: 100%;

        p {
          font-size: 18px;
          color: $codGray;

          &.isActive {
            font-weight: bold;
          }
        }

        &.clickable {
          &:hover {
            cursor: pointer;
            color: $codGray60;
          }
        }

        .separator-vert {
          width: 1px;
          background-color: $cerulean;
          height: 90%;
          margin: 0 30px;
        }
      }
    }
    .filters {
      .filter-label {
        font-size: 14px;
        color: $stormDust;
        padding-left: 6px;
      }
    }
    .label {
      align-self: center;
      font-weight: bold;
      font-size: 18px;
    }
  }
}
