@import '../../../assets/colors';

#searchBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 80%;
  min-width: 175px;
  border: 1px solid rgba($cornflowerBlue, 0.3);
  border-radius: 3px;
  box-sizing: border-box;
  padding: 5px 15px;
  margin: 5px;
  height: 40px;
  input {
    height: 30px;
    flex: 1;
    border: none;
    outline: none;
    font-size: 14px;
  }

  i {
    color: $codGray;
  }
}
