.table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
}

.logs-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.1);

  th, td {
    padding: 12px 15px;
    text-align: left;
  }

  thead {
    background-color: #00A0AF;
    color: white;
    gap: 10px;

    th {
      font-weight: bold;
      text-transform: uppercase;
      font-size: 14px;
    }
  }

  tbody {
    tr {
      &.log-success {
        background-color: #d4edda;
        color: #155724;
      }

      &.log-failure {
        background-color: #f8d7da;
        color: #721c24;
      }

      &:nth-of-type(even):not(.log-success):not(.log-failure) {
        background-color: #f9f9f9;
      }

      &:hover {
        background-color: #f1f1f1;
      }

      td {
        border-bottom: 1px solid #ddd;
      }
    }
  }
}
