@import "../../../../assets/colors";
@import "../../../../assets/dimens";

#project-creation {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: $marginTopContent;
  padding: 0 5%;

  .side-bar {
    width: 25%;
  }

  .project {
    display: flex;
    width: 75%;
    flex-direction: column;

    .action-button {
      display: flex;
      align-self: flex-end;
      justify-content: center;
      color: $white;
      height: 40px;
      width: 172px;
      margin-bottom: 20px;

      span {
        font-size: $labelButton;
      }
    }
  }
}

@media screen and (max-width: 940px) {
  #project-screen {
    flex-direction: column;

    .side-bar {
      display: none
    }

    .project {
      width: 100%
    }
  }
}
