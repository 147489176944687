@import "../../../../assets/colors";

#confirm-popup-background {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  opacity: 0;
  background-color: $codGray60;
  transition: z-index, opacity 0.1s ease-in;

  &.show {
    z-index: 10;
    opacity: 1;
  }

  &.hide {
    transition: none;
    z-index: -1;
  }

  #confirm-popup {
    position: relative;
    background-color: $white;
    transition: z-index, opacity 0.2s ease-in;
    transition-delay: 0.1s;
    box-sizing: border-box;
    padding: 50px 30px 20px 30px;

    &.show {
      z-index: 10;
      opacity: 1;
    }

    &.hide {
      z-index: -1;
      opacity: 0;
      transition-delay: 0s;
    }

    i {
      position: absolute;
      top: 10px;
      right: 10px;
      color: $codGray;

      &:hover {
        color: $bombay;
        font-size: 30px;
        top: 7px;
        right: 7px;
        cursor: pointer;
      }
    }

    .title {
      margin-bottom: 64px;
      color: $codGray;
      font-size: 24px;
      font-weight: bold;
      letter-spacing: 0;
      line-height: 32px;
      text-align: center;
    }

    .message {
      box-sizing: border-box;
      margin: 0 0 30px 0;
      color: $codGray;
      text-align: center;
      white-space: pre-line;
    }

    .popup-actions {
      display: flex;
      flex-direction: row;
      justify-content: center;

      .edit-button {
        margin: 0 10px;
      }
    }
  }
}

