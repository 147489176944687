.panel {
  border-radius: 2px;
  background-color: #FFFFFF;
  box-shadow: 0 1px 2px 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(145,145,145,0.3);
  margin-bottom: 20px;
  padding: 66px 100px;
}

@media screen and (max-width: 1180px) {
  .panel {
    padding: 24px 50px;
  }
}

@media screen and (max-width: 800px) {
  .panel {
    padding: 16px 25px;
  }
}