@import "../../../assets/colors";

.tab-container {
  display: flex;
  flex-direction: row;

  .tab-wrapper {
    display: flex;
    flex-direction: row;

    .tab {
      display: flex;
      flex-direction: row;
      justify-content: center;
      padding: 12px;
      font-size: 15px;
      color: $codGray;
      cursor: pointer;

      &.selected {
        font-weight: bold;
      }

      &:hover {
        font-weight: bold;
      }
    }

    .separator {
      width: 1px;
      height: 100%;
      margin-left: 20px;
      margin-right: 20px;
      background-color: $codGray;
    }
  }
}

@media screen and (max-width: 640px) {
  .tab-container {
    width: 100%;

    .tab {
      flex: 1;
    }
  }
}


