@import "../../../../assets/colors";

#sideNavigation {
  position: fixed;
  top: calc(72px + 32px);
  left: 6%;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  .actions {
    display: flex;
    flex-direction: column;

    .button {
      width: 150px;
      height: auto;
      padding: 8px 16px;
      margin: 6px;
    }
  }

  .step-navigation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 6px;
    margin-top: 12px;
    box-sizing: border-box;

    .vl {
      width: 2px;
      height: 40px;
      background-color: $alto;
    }

    i {
      padding: 4px;
      font-size: 36px;
      color: $treePoppy;
      background-color: $wildSand;
      border-radius: 24px;
      cursor: pointer;

      &:hover {
        background-color: darken($wildSand, 10);
      }

      &.disabled {
        background-color: $whiteIce;
        cursor: not-allowed;

        &:hover {
          background-color: $whiteIce;
        }
      }
    }
  }
}
