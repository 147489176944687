#screenWrapper {
  min-height: calc(100vh - 64px - 72px);
  display: flex;
  flex-direction: row;
  padding: 32px 48px 32px 48px;
  overflow-y: auto;

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 800px) {
  #screenWrapper {
    padding: 16px 24px 16px 24px;
  }
}
