@import "../../../assets/colors";
@import "../../../assets/dimens";

.button {
  padding: 18px 10px 16px 10px;
  text-align: center;
  cursor: pointer;
  border-radius: 24px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .1s ease-in background-color;

  span {
    font-size: 14px;
    line-height: 16px;
    color: $white;
  }

  &.orange {
    background-color: $treePoppy;
    &:hover {
      background-color: lighten($treePoppy, 10);
    }
  }

  &.green {
    background-color: $mountainMeadow;
    &:hover {
      background-color: lighten($mountainMeadow, 10);
    }
  }

  &.blue {
    background-color: $bondiBlue;
    &:hover {
      background-color: lighten($bondiBlue, 10);
    }
  }

  &.dark-grey {
    background-color: $stormDust;
    &:hover {
      background-color: lighten($stormDust, 10);
    }
  }

  &.grey {
    background-color: $wildSand;

    &:hover {
      background-color: darken($wildSand, 10);
    }

    span {
      color: $cerulean;
    }

    i {
      color: $treePoppy;
    }
  }

  i {
    margin-left: auto;
  }

  &.disabled {
    background-color: $bombay;
    &:hover {
      background-color: $bombay;
    }
    cursor: not-allowed;
  }
}

.edit-button {
  color: $white;
  height: 36px;
  width: 125px;
  padding-left: 15px;

  span {
    font-size: $labelButton;
  }

  i {
    font-size: $iconButton;
  }
}

.add-button {
  color: $white;
  height: 36px;
  width: 125px;
  padding-left: 15px;

  span {
    font-size: $labelButton;
  }

  i {
    font-size: $iconButton;
  }
}

#selector-button{
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  >.selector-list {
    position: absolute;
    top: 45px;
    right: 0;
    left: 0;
    max-height: 600px;
    overflow-y: scroll;
    border-radius: 24px;
    flex-direction: column;
    background-color: $white;
    opacity: 0;
    z-index: -1;
    place-content: flex-start;
    transition: opacity .2s ease-in;
    box-shadow: 2px 2px 3px 2px rgba(0, 0, 0, 0.08), 0 2px 3px 0 rgba(0, 0, 0, 0.08), 2px 2px 3px 0 rgba(0, 0, 0, 0.1);

    .row {
      display: flex;
      align-items: center;
      min-height:55px;
      width: 100%;
      box-sizing: border-box;
      padding: 8px 12px;
      transition: background-color .2s ease-in;

      p {
        color: black;
        font-size: 16px;
      }

      &.orange{
        &:hover {
          background-color: $treePoppy30;
          cursor: pointer;
        }
      }

      &.green{
        &:hover {
          background-color: $atlantis10;
          cursor: pointer;
        }
      }

      &:last-child {
        border-bottom: none;
      }
    }

    &.display {
      opacity: 1;
      z-index: 10;
    }
  }
}
