@import "../../../../../assets/dimens";

#school-details {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: $marginTopContent 32px;

  @include width800px {
    flex-direction: column;
    align-items: center;
    margin: 24px;
  }

  .side-bar {
    width: 25%;

    .wrapper {
      display: flex;
      flex-direction: column;
      position: fixed;

      #sideBar {
        position: relative;
      }

      .actions {
        margin-top: 16px;

        .button {
          min-width: 175px;
          margin-top: 16px;
        }
      }
    }
  }

  .school-details-panel {
    width: 75%;
  }
}
