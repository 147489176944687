@import '../../../assets/colors';

#sideBar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;  

  .back-link {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 25px;
    white-space: nowrap;

    i {
      color: $rollingStone;
      margin-right: 15px;
    }

    .link {
      font-size: 13px;
      color: #000;
    }

    &:hover{
      cursor: pointer;
    }
  }

  .menu {
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1), 0 1px 3px 0 rgba(145, 145, 145, 0.3);
    box-sizing: border-box;
    padding: 25px;
    margin-top: 35px;
    border-radius: 2px;
    background-color: $white;

    li {
      cursor: pointer;
      list-style: none;
      margin: 14px 0;

      &.bold {
        font-weight: bold;
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  #side-bar {
    .menu {
      padding: 4px 16px;
      box-shadow: 0 3px 2px 0 rgba(10,15,44,0.2), 0 4px 8px 0 rgba(104,129,151,0.15), 0 8px 12px 0 rgba(0,0,0,0.15);
    }
  }
}
