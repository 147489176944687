@import "../../../../assets/colors";
@import "../../../../assets/dimens";

#projectSchools {
  > .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: $title;
      font-weight: bold;
    }
  }

  .school {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;

    .code {
      margin-right: 24px;
    }
    
    .code,
    .name {
      font-size: 13px;
      color: #1e1e1e;
      font-weight: 600;
    }

    .name-wrapper {
      flex: 1 1;
      min-width: 100px;
      margin-right: 24px;

      .name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .address {
        font-size: 12px;
        color: #535d5f;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }
}
