@import "../../../../assets/dimens";
@import "../../../../assets/colors";

#facilitator-creation-screen {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: $marginTopContent;
  padding: 0 5%;

  .side-bar {
    width: 25%;
  }

  .facilitator-creation {
    display: flex;
    width: 75%;
    flex-direction: column;

    .button {
      display: flex;
      align-self: flex-end;
      justify-content: center;
      color: $white;
      height: 40px;
      width: 172px;
      margin-bottom: 20px;

      span {
        font-size: $labelButton;
      }
    }
  }
}

@media screen and (max-width: 940px) {
  #facilitator-details-screen {
    flex-direction: column;

    .side-bar {
      display: none
    }

    .facilitator-creation {
      width: 100%
    }
  }
}

